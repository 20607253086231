import BaseClass from '@/utils/baseclass'

export default class RawMaterialPlanning extends BaseClass {
  addItem(item) {
    let newItem = new RawMaterial(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      this.id = item.id
      this.code = item.code
      this.storeId = item.storeId
      this.storeName = item.storeName
      this.status = item.status
      this.planningDate = item.planningDate
      this.type = item.type
      this.information = item.information
      this.detailList = []
      this.showDetail = false
      this.toStoreIds = item.toStoreIds
    }
  }
}
export class RawMaterial {
  constructor(item) {
    this.id = item.id
    this.code = item.code
    this.storeId = item.storeId
    this.storeName = item.storeName
    this.status = item.status
    this.planningDate = item.planningDate
    this.type = item.type
    this.information = item.information
    this.detailList = []
    this.showDetail = false
    this.isRawMaterial = false
    this.toStoreIds = item.toStoreIds
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new RawMaterialDetail(detail))
    })
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id)
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1)
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex(item => item.id === Id)
  }

  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }

  updateRawListItem(data) {
    let findItem = this.detailList.find(item => item.stockItemId === data.stockItemId)
    if (findItem) {
      findItem.quantity = data.quantity
      findItem.rawMaterialPlanningHeaderId = data.rawMaterialPlanningHeaderId
      findItem.requestType = data.requestType
      findItem.requestedStoreId = data.requestedStoreId
      findItem.stockItemId = data.stockItemId
    }
  }

  get detailIsEmpty() {
    return this.detailList.length === 0
  }
}

export class RawMaterialDetail {
  constructor(detail) {
    this.id = detail.id
    this.rawMaterialPlanningHeaderId = detail.rawMaterialPlanningHeaderId
    this.storeId = detail.storeId
    this.headerCode = detail.headerCode
    this.stockItemId = detail.stockItemId
    this.stockItemName = detail.stockItemName
    this.quantity = detail.quantity
    this.requestedQuantity = detail.requestedQuantity
    this.stockOnHandQuantity = detail.stockOnHandQuantity
    this.recommendedQuantity = detail.recommendedQuantity
    this.onComingQuantity = detail.onComingQuantity
    this.differenceQuantity = detail.differenceQuantity
    this.orderedQuantity = detail.orderedQuantity
    this.baseUnitId = detail.baseUnitId
    this.baseUnitName = detail.baseUnitName
    this.storeName = detail.storeName
    this.planningDate = detail.planningDate
    this.requestType = detail.requestType
    this.requestedStoreId = detail.requestedStoreId
    this.status = detail.status
    this.unitId = detail.unitId
    this.unitName = detail.unitName

  }
}
