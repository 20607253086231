<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :isGetDataLoading="isSettingData",
  :tableName="$t('Production.removeHeaderTitle')",
  loading="deleteProduction",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'ProductionHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'code',
        is_visible: true,
        short_title: this.$t('Dictionary.DocNo'),
        title: this.$t('Dictionary.DocNo')
      },
      {
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('Dictionary.Store'),
        title: this.$t('Dictionary.Store')
      },
      {
        name: 'productionDate',
        is_visible: true,
        short_title: this.$t('Dictionary.Date'),
        title: this.$t('Dictionary.Date')
      }]
    }
  },
  computed: {
    isSettingData () {
      return this.$wait.is('fetchProduct')
    }
  },
  async mounted () {
    const res = await this.fetchProduct(this.$route.params.id)
    if (res) {
      this.info = {
        ...res.data.productionHeader,
        productionDate: moment(res.data.productionHeader.productionDate).format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    ...mapActions('Production', [
      'deleteProduction',
      'fetchProduct'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteProduction(this.$route.params.id)
      if (res) {
        const message = this.$t('Global.successRemoveMessage', { field: this.$t('Dictionary.Production') })
        this.notifyShow({ message })
        this.closePopup()
        this.$emit('getProductionsList')
      }
    }, 'itemRemove')
  }
}
</script>
