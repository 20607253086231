<template lang="pug">
 Popup
    template(slot="title")
     template  {{ isEdit ? $t('Global.editData', { field: $t('Dictionary.rawMaterialPlanningTitle')}) : $t('Global.addData', { field: $t('Dictionary.rawMaterialPlanningTitle') }) }}

    template(v-if="isEdit" slot="detail")
      div.m-top-25
        h4 {{ $t('Dictionary.DocNo') }}
        span.ff-mono {{ form.code }}

    template(slot="content")
      Loading(v-if="isPageLoading")
      form.Form(
        v-else
        @submit.prevent="onSubmitForm"
        id="raw-material-planning-header-form")

        .Form-item.required
          label.Form-item-label
            | {{ $t('Stores.type_Store') }}
          .control.form-item-select
            custom-search(
              ref="firstInput"
              component-name="raw-material-planing-header-store"
              name="storeName",
              v-model="form.storeId"
              :data-vv-as="$t('Stores.type_Store')",
              v-validate="'required|is_not:-1'",
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              :disabled="isEdit"
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :loading="isSearchStoreLists"
              :placeholder="$t('ProductionPlaning.Raw_Material_Popup_StoreName_Placeholder')"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )
        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Date') }}
          .control.form-item-select.m-top-25
            CustomDatepicker(
              id="raw-material-planing-header-planning-date",
              name="planningDate",
              :data-vv-as="$t('Dictionary.Date') ",
              v-model="form.planningDate",
              :class="{ 'is-danger': veeErrors.has(`planningDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabled="isEdit"
              :disabledStartDate="disableDate")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('planningDate')"
          ) {{ veeErrors.first('planningDate') }}

        .Form-item.border-bottom-gray.p-bottom-10(v-if="!isEdit")
          MultiRadioSelect(
            v-model="type",
            :data="selectType()"
          )

        template(v-if="type === 1")
          .Form-item(:class="isOnlyListUsage ? 'required' : '' ")
            MultiSearchSelect(
              v-model="ProductionList",
              searchAction="ListsProduction/searchProductionListHeader",
              searchResultKey="productionListHeaderList",
              :formLabel="$t('Dictionary.List')",
              moduleName="production-planning",
              name="raw-material-planning-header-form-list"
              v-validate="{required: isOnlyListUsage}"
              :error="veeErrors.has('raw-material-planning-header-form-list')"
              :searchAll="true"
            )
          showValidateError(
            v-show="veeErrors.has('raw-material-planning-header-form-list')"
            :errorName="veeErrors.first('raw-material-planning-header-form-list')"
          )
        
        template(v-if="type === 3")
          .Form-item
            MultiSearchSelect(
              v-model="planningList",
              searchAction="ProductionPlaning/searchProductPlanning",
              searchResultKey="productionPlanningHeaderList",
              :formLabel="$t('ProductionPlaning.ProductionPlaning')",
              moduleName="productionPlanningList",
              :isSearchListResetInEveryAction="true",
              searchLabel="nameWithDate",
              :hasSearchPayload="true",
              :searchPayload="productionPlanninglistSearchPayload"
            )
            showValidateError(
              v-show="veeErrors.has('raw-material-planning-header-form-list')"
              :errorName="veeErrors.first('raw-material-planning-header-form-list')"
            )


        template(v-else-if="type === 2")
          .m-top-20
            .Form-item.required
              label.Form-item-label {{ $t('Dictionary.Date') }}
              .flexbox.align-center
                .picker
                  .control.form-item-select
                    CustomDatepicker(
                      id="raw-material-planing-header-planning-request-start-date"
                      name="startDate",
                      :data-vv-as="$t('Dictionary.Date') ",
                      v-model="form.dateStart",
                      :class="{ 'is-danger': veeErrors.has(`startDate`) }"
                      v-validate="'required'"
                      pickerType="manuel",
                      inputIconRight="icon-datepicker-big"
                      inputIcon="",
                      position="top-left",
                      :disabledStartDate="dateStartDisable")
                  p.Form-item-help.is-danger(
                  v-show="veeErrors.has('startDate')"
                  ) {{ veeErrors.first('startDate') }}

                .picker.m-left-20
                  .control.form-item-select
                    CustomDatepicker(
                      id="raw-material-planing-header-planning-request-end-date"
                      name="endDate",
                      :data-vv-as="$t('Dictionary.Date') ",
                      v-model="form.dateEnd",
                      :class="{ 'is-danger': veeErrors.has(`endDate`) }"
                      v-validate="'required'"
                      pickerType="manuel",
                      inputIconRight="icon-datepicker-big"
                      inputIcon="",
                      position="top",
                      :disabledStartDate="dateEndDisable")

                  p.Form-item-help.is-danger(
                  v-show="veeErrors.has('endDate')"
                  ) {{ veeErrors.first('endDate') }}

            .Form-item.required
              label.Form-item-label
                | {{ $t('ProductionPlaning.requestedStore') }}

              .control.form-item-select
                Icon(name="icon-down-arrow")
                customSelectInput(
                  name="fromStoreName",
                  :optionData="requestedStoreSelectList"
                  v-model="form.toStoreId"
                  id="raw-material-planing-header-planning-requested-from-store"
                )

              .items
                SelectedList(
                  label="name",
                  moduleName="raw-material-planing",
                  v-model="toStoreList"
                )

        .Form-item.m-top-20
          label.Form-item-label {{ $t('Global.information') }}
          .control
            textarea.txt.requestFormItem#productionPlaning-header-form-description(
              name="information"
              autocomplete="off"
              :data-vv-as="$t('Global.information')"
              v-model="form.information"
              :class="{ 'is-danger': veeErrors.has('information')}",
              rows="3"
              ref="descriptionInput"
              v-validate="'max:512'",
              type="text")

              p.Form-item-help.is-danger(
                v-show="veeErrors.has('information')"
              ) {{ veeErrors.first('information') }}

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        id="raw-material-planning-submit-button",
        form="raw-material-planning-header-form",
        :disabled="isCreating || (type === 2 && toStoreList.length === 0)"
        :isLoading="isCreating"
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('Dictionary.rawMaterialPlanningTitle') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('Dictionary.rawMaterialPlanningTitle') }) }}
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import moment from 'moment'
import MultiRadioSelect from '@/view/global/multiselect-radio'
import SelectedList from '@/view/global/selected-list'
import customSelect from '@/view/global/custom-select'
import MultiSearchSelect from '@/view/global/multi-search-select'

export default {
  name: 'RawMaterialPlanningHeaderForm',

  components: {
    MultiRadioSelect,
    SelectedList,
    customSelect,
    MultiSearchSelect
  },

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      type: 0,
      form: {
        storeId: {
          id: '-1',
          name: ''
        },
        planningDate: new Date(),
        dateStart: moment(1, 'DD').hour(0).minute(0)._d,
        dateEnd: moment().hour(0).minute(0)._d,
        information: null,
        toStoreId: '-1',
        code: null
      },
      planningList: [],
      requestedStoreList: [],
      ProductionList: [],
      selectMiniStoreList: [],
      disableDate: {
        to: moment()._d
      },
      toStoreList: [],
      isOnlyListUsage: false
    }
  },

  computed: {
    ...mapGetters('Stores', ['storeList']),
    ...mapGetters('Settings', ['UserInfo', 'permissions']),
    ...mapGetters('RawMaterialPlannig', ['RawMaterialHeaderList']),

    storeListItems () {
      return this.isEdit ? this.storeList : this.storeList.filter(i => i.canProduction)
    },

    productionPlanninglistSearchPayload () {
      return {
        storeId: this.form.storeId.id
      }
    },

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    dateStartDisable () {
      return {
        from: moment(this.form.dateEnd)._d
      }
    },

    dateEndDisable () {
      return {
        to: moment(this.form.dateStart)._d
      }
    },

    isCreating () {
      return this.$wait.is(['createRawMaterialPlanningHeader', 'onSubmitForm'])
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    isEdit () {
      return this.status === 'edit'
    },

    requestedStoreSelectList () {
      let selectList = []
      this.requestedStoreList.map(i => {
        if (!this.toStoreList.some(el => el.id === i.id)) selectList.push(i)
      })
      return selectList
    }
  },

  methods: {

    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Stores', ['getStoreList','fetchStoreByName']),
    ...mapActions('RawMaterialPlanning', [
      'createRawMaterialPlanningHeader',
      'updateRawMaterialPlanningHeader',
      'fetchRawMaterialPlanningHeader',
      'fetchRequestedItemsForList',
      'fetchRawMaterialPlanningDetailList'
    ]),

    ...mapMutations('RawMaterialPlanning', ['SHOW_DETAIL']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    setForm (item) {
      this.form.storeId = { id: item.storeId, name: item.storeName }
      this.form.planningDate = item.planningDate
      this.form.information = item.information
      this.form.code = item.code
    },

    selectType () {
      if (this.isOnlyListUsage) this.type = 1
      return [
        {
          text: this.$t('Global.manuel'),
          value: 0,
          disabled: this.isOnlyListUsage
        },
        {
          text: this.$t('Dictionary.List'),
          value: 1
        },
        {
          text: this.$t('ProductionPlaning.showRequest'),
          value: 2,
          disabled: this.form.storeId.id === '-1' || this.isOnlyListUsage
        },
        {
          text: this.$t('ProductionPlaning.ProductionPlaning'),
          value: 3
        }
      ]
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            const canProductionList = res.data.items.filter(obj1 => 
              this.storeListItems.some(obj2 => obj2.id === obj1.id)
            );
            this.selectMiniStoreList = canProductionList
          }
        })
    },
    inputSelection (data) {
      this.form.storeId.id = data.id
      this.form.storeId.name = data.name
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    async newRawMaterialPlanning () {
      const payload = {
        storeId: this.form.storeId.id,
        planningDate: moment(this.form.planningDate).format('YYYY-MM-DD'),
        information: this.form.information
      }

      if (this.type === 1) {
        payload.productionListHeaderIds = this.ProductionList.map(_ => _.id)
      } else if (this.type === 2) {
        payload.insertWithRequests = true
        payload.startDate = moment(this.form.dateStart).format('YYYY-MM-DD')
        payload.endDate = moment(this.form.dateEnd).format('YYYY-MM-DD')
        payload.toStoreIds = this.toStoreList.map(_ => _.id)
      } else if (this.type === 3)  payload.productionPlanningHeaderIds = this.planningList.map(_ => _.id)
      const res = await this.createRawMaterialPlanningHeader(payload)
      if (res) {
        this.$emit('showDrafts')
        this.$emit('getRawMaterialList')
        const message = this.$t('Global.successCreateMessage', { field: this.$t('Dictionary.rawMaterialPlanningTitle') })
        this.notifyShow({ message })
        this.closePopup()
        const header = res.data
        if (this.type === 0) {
          this.$router.push({
            params: {
              header,
              headerId: header.id
            },
            name: 'NewRawMaterialPlanningDetail'
          })
        }
        if (this.type === 3) {
          const res = await this.fetchRawMaterialPlanningDetailList(header.id)
          if (res) {
            const detail = res.data.rawMaterialPlanningDetailList
            this.SHOW_DETAIL({
              RawMaterialPlaningHeader: {
                id: header.id
              },
              RawMaterialPlanningDetailList: detail
            })
          }
        }
      }
    },

    async getRequestedStoreList () {
      if (this.type !== 2) return
      const payload = {
        fromStoreId: this.form.storeId.id,
        StartDate: moment(this.form.dateStart).format('YYYY-MM-DD'),
        EndDate: moment(this.form.dateEnd).format('YYYY-MM-DD')
      }
      const res = await this.fetchRequestedItemsForList(payload)
      if (res) {
        this.requestedStoreList = res.data.storeList
        this.form.toStoreId = '-1'
        this.toStoreList = []
      }
    },
    async editRawMaterialPlanning () {
      const payload = {
        id: this.$route.params.id,
        information: this.form.information
      }
      await this.updateRawMaterialPlanningHeader(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('Global.successUpdateMessage', { field: this.$t('Dictionary.rawMaterialPlanningTitle') })
          this.notifyShow({ message })
          this.$emit('getRawMaterialList')
          this.closePopup()
        })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) await this.editRawMaterialPlanning()
        else await this.newRawMaterialPlanning()
      })
    }, 'onSubmitForm')

  },

  watch: {
    'form.toStoreId' (val) {
      if (val === '-1' || this.toStoreList.find(item => item === val)) return
      const item = this.requestedStoreList.find(_ => _.id === val)
      this.toStoreList.push(item)
      this.form.toStoreId = '-1'
    },

    toStoreList (val) {
      if (val.length === 0) {
        this.form.toStoreId = '-1'
      }
    },

    async 'form.storeId' (val) {
      await this.getRequestedStoreList()
    },

    async type () {
      await this.getRequestedStoreList()
    },

    async 'form.dateStart' () {
      await this.getRequestedStoreList()
    },

    async 'form.dateEnd' () {
      await this.getRequestedStoreList()
    }
  },

  async beforeMount () {
    this.$wait.start('pageLoading')
    await this.getStoreList({pageNumber: 1, pageSize: 9999})
    if (this.isEdit) {
      if (this.$route.params.item) this.setForm(this.$route.params.item)
      else {
        this.fetchRawMaterialPlanningHeader(this.$route.params.id)
          .then(res => {
            if (!res) return
            this.setForm(res.data.rawMaterialHeader)
          })
      }
    } else {
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
      const isProductionStoreExist = this.storeListItems.find(i => i.id === this.UserInfo.defaultStoreId)
      if (isProductionStoreExist) this.form.storeId = { id: this.UserInfo.defaultStoreId, name: this.UserInfo.defaultStoreName }
    }
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

  :deep() .datepicker-ui .calendar .picker .header{
    height: unset;
  }
  :deep() .inputfield {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
  }
  :deep() .label.popup-item-check {
    width: 45% !important;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
</style>
