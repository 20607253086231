var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "zoom" } }, [
    _vm.isPopupVisible
      ? _c(
          "div",
          { staticClass: "Alert" },
          [
            _c("div", { staticClass: "Table-body" }, [
              _c(
                "div",
                { staticClass: "Table" },
                [
                  _c("h4", { staticClass: "Table-title m-bottom-15" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Dictionary.rawMaterialPlanningTitle"))
                    ),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.description))]),
                  _c("TableView", {
                    staticClass: "m-top-15",
                    attrs: {
                      componentName: "info-alert",
                      data: _vm.data,
                      fields: _vm.fields,
                      actions: ["export"],
                      readOnly:
                        _vm.isProductItemWarning || _vm.isStockItemWarning,
                    },
                    on: { delegateOnItemExport: _vm.showExportPopup },
                  }),
                  _c("portal", {
                    attrs: { to: "requestType" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (data) {
                            return _c("div", {}, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.checkRequestType(data))),
                              ]),
                            ])
                          },
                        },
                      ],
                      null,
                      false,
                      3900460322
                    ),
                  }),
                  _c("portal", {
                    attrs: { to: "code" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (header) {
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "Button",
                                  {
                                    staticClass: "bgc-none",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goRequestedLists(header)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(header.code))])]
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3834677441
                    ),
                  }),
                  _c(
                    "div",
                    { staticClass: "Table-footer m-top-15" },
                    [
                      _c(
                        "Button",
                        {
                          ref: "focusedButton",
                          attrs: {
                            primary: "",
                            size: "medium",
                            id: "btn-info-alert-cancel",
                            type: "button",
                          },
                          on: { click: _vm.closePopup },
                        },
                        [_vm._v(_vm._s(_vm.closePopupText))]
                      ),
                      _vm.isProductItemWarning || _vm.isStockItemWarning
                        ? _c(
                            "Button",
                            {
                              ref: "focusedButton",
                              staticClass: "m-left-10",
                              attrs: {
                                danger: "",
                                size: "medium",
                                id: "btn-info-alert-cancel",
                                type: "button",
                              },
                              on: { click: _vm.deletePopupItems },
                            },
                            [_vm._v(_vm._s(_vm.$t("Dictionary.Delete")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.isExportPopupVisible
              ? _c("exportPopup", {
                  ref: "exportPopupTemplate",
                  attrs: {
                    templateId: _vm.templateId,
                    templateType: _vm.templateType,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }