<template lang="pug">
 Popup
    template(slot="title")
      div {{ formTitle }}

    template(slot="detail")
      div.m-top-25
       h4.m-bottom-10 {{ $t('Dictionary.DocNo') }}
       span.ff-mono {{ headerInfo.code }}

    template(slot="content")
      form.Form(
       id="Production-Detail-Form",
       @submit.prevent="onSubmitForm"
      )
        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.ProductItem') }}
          .control
            custom-search(
              component-name="form-production-detail-stock-item"
              name="productItem"
              v-model="form.productItem"
              :data-vv-as="$t('Dictionary.ProductItem')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('productItem') }"
              ref="focusableInput"
              :on-search="getOptions"
              :options="selectOptions"
              :disabled="isEdit"
              label="name"
              :loading="isSearchingStockItem"
              :placeholder="$t('Global.search_Placeholder')"
              :directSelectOption="directSelectOption"
              @input="fetchStockItems"
            )
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('productItem')"
          ) {{ veeErrors.first('productItem') }}

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Unit') }}
          .control.with-icon
            .form-item-select
              Icon.icon-down-arrow(name="icon-down-arrow")
              customSelectInput(
                name="unitId",
                :disabled="isEdit",
                :optionData="filteredUnitList"
                v-model="form.productItem.unitId",
                v-validate="'required|is_not:-1'",
                :data-vv-as="$t('Dictionary.Unit')",
                :error="veeErrors.has('unitId')",
                @input="fetchStockItems")

              showValidateError(
                v-show="veeErrors.has('unitId')"
                :errorName="veeErrors.first('unitId')")

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Quantity') }}
          .control
            custom-number-input(
              id="production-detail-form-quantity"
              name="quantity"
              :data-vv-as="$t('Dictionary.Quantity')"
              v-model="form.quantity"
              v-validate="{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
              :error="veeErrors.has('quantity')"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

            div.flexbox.m-top-10.font-size-small(v-if="form.productItem")
              p.c-success.font-weight-bold {{ $t('Dictionary.recipe_cost_info') }}
              p.m-left-10.font-weight-normal.c-dark {{ stockItemInfo.unitPrice | formatCurrency }}
              p.c-success.font-weight-bold.m-left-10 {{ $t('Production.StockOnHandQuantity') }}
              p.m-left-10.font-weight-normal.c-dark {{ stockItemInfo.baseUnitQuantity }}

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        v-if="!isEdit"
        id="checkbox-inventory-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Global.detail') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary,
        size="medium"
        type="submit"
        variant="full"
        id="button-production-action-detail",
        form="Production-Detail-Form",
        :disabled="isRunAction",
        :isLoading="isRunAction"
      )
        span {{ formTitle }}

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'

//
import moment from 'moment'

export default {
  name: 'ProductionDetailForm',
  mixins: [getQuantityFromBarcode],
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      saveAndNew: true,
      selectOptions: [],
      form: {
        productItem: {
          id: null,
          name: null,
          unitId: null
        },
        quantity: null
      },
      stockItemInfo: {},
      dates: [ moment(1, 'DD').hour(0).minute(0)._d, moment().hour(0).minute(0)._d ],
      storeFilter: []
    }
  },
  computed: {
    filteredUnitList () {
      if (!this.form.productItem.id) return []
      return this.getRelatedUnitList(this.form.productItem.baseUnitId || this.form.productItem.unitId)
    },

    isBarcodeSearch () {
      return this.selectOptions.length === 1 &&
      this.selectOptions[0].matchType === 2
    },

    directSelectOption () {
      return this.isBarcodeSearch
        ? this.selectOptions[0]
        : {}
    },
    isSearchingStockItem () {
      return this.$wait.is(['Stock/searchStockItem'])
    },
    isEdit () {
      return this.status === 'edit'
    },
    itemId () {
      if (!this.isEdit) return
      return this.$route.params.id
    },
    formTitle () {
      if (this.isEdit) {
        return this.$t('Global.editDataDetail', { field: this.$t('Dictionary.Production') })
      } else {
        return this.$t('Global.addDataDetail', { field: this.$t('Dictionary.Production') })
      }
    },
    headerInfo () {
      const header = this.$route.params.header
      return {
        id: header.id,
        code: header.code,
        storeId: header.storeId
      }
    },
    //
    isRunAction () {
      return this.$wait.is(['createProductionDetail', 'updateProductionDetail', 'onSubmitFormDetailProduction'])
    },
    //
    ...mapGetters('Units', ['findUnit', 'getRelatedUnitList']),
    ...mapGetters('Production', ['Page']),
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Production', [
      'createProductionDetail',
      'updateProductionDetail',
      'fetchProductDetailInfo',
      'fetchProduct'
    ]),
    ...mapActions('Transfer', ['getStockInfo']),
    ...mapMutations('Production', [
      'HIDE_DETAIL',
      'SHOW_DETAIL',
      'HIDE_OTHERS_DETAILS'
    ]),

    getOptions (search) {
      const decoratedValue = this.getWeightedBarcode(search)
      this.searchStockItem({ Text: decoratedValue, types: [ 2 ] })
        .then(res => {
          this.selectOptions = res.data.items
          if (this.isBarcodeSearch) {
            const baseUnitId = res.data.items[0].baseUnitId
            const parsedBarcode = this.getQuantityFromBarcode(search, baseUnitId)
            if (parsedBarcode.isWeighted) this.form.quantity = parsedBarcode.quantity
          }
        })
    },

    formFocus () {
      this.$refs.focusableInput.$refs.search.focus()
    },

    formReset () {
      this.form = {
        productItem: {
          id: null,
          name: null,
          unitId: null
        },
        quantity: null
      }
      this.selectOptions = []
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async addNewDetail () {
      const payload = {
        productionStockItemId: this.form.productItem.id,
        quantity: this.form.quantity,
        productionHeaderId: this.headerInfo.id,
        unitId: this.form.productItem.unitId
      }
      await this.createProductionDetail(payload)
        .then(async (res) => {
          if (!res) return
          const message = this.$t('Global.successDetailCreateMessage', { field: this.$t('Dictionary.Production') })
          this.notifyShow({ message })
          await this.fetchProduct(this.headerInfo.id)
          if (this.saveAndNew) {
            this.formReset()
            this.$nextTick(() => {
              this.formFocus()
            })
          } else {
            this.close()
          }
        })
    },

    async editDetail () {
      const payload = {
        quantity: this.form.quantity,
        productionDetailId: this.itemId
      }
      await this.updateProductionDetail(payload)
      this.fetchProduct(this.headerInfo.id)
      const message = this.$t('Global.successDetailUpdateMessage', { field: this.$t('Dictionary.Production') })
      this.notifyShow({ message })
      this.close()
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (isValid) {
        if (this.isEdit) {
          await this.editDetail()
        } else {
          await this.addNewDetail()
        }
      }
    }, 'onSubmitFormDetailProduction'),

    async fetchStockItems () {
      const payload = {
        storeId: this.headerInfo.storeId,
        stockItemId: this.form.productItem.id,
        unitId: this.form.productItem.unitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }

      await this.getStockInfo(payload)
        .then(res => {
          this.stockItemInfo = res.data
        })
    }
  },
  async mounted () {
    if (this.isEdit) {
      const res = await this.fetchProductDetailInfo(this.itemId)
      if (res) {
        const { productionDetail } = res.data

        this.form.quantity = productionDetail.quantity
        this.form.productItem = {
          id: productionDetail.productionStockItemId,
          name: productionDetail.productionStockItemName,
          unitId: productionDetail.unitId
        }
        await this.fetchStockItems()
      }
    } else {
      this.$nextTick(() => {
        this.formFocus()
      })
    }
  }
}
</script>
