import BaseClass from "@/utils/baseclass";
import store from "@/store";

export default class findItemProductions extends BaseClass {
  addItem(item) {
    let newItem = new Production(item);
    this.list.push(newItem);
  }

  updateItem(item) {
    let findItem = this.findItem(item.id);
    if (findItem) {
      findItem.id = item.id;
      findItem.code = item.code;
      findItem.storeId = item.storeId;
      findItem.storeName = item.storeName;
      findItem.productionDate = item.productionDate;
      findItem.totalAmount = item.totalAmount;
      findItem.description = item.description;
      findItem.showDetail = false;
    }
  }
}

export class Production {
  constructor(item) {
    this.id = item.id;
    this.code = item.code;
    this.storeId = item.storeId;
    this.storeName = item.storeName;
    this.status = item.status;
    this.productionDate = item.productionDate;
    this.detailList = [];
    this.showDetail = false;
    this.totalAmount = item.totalAmount;
    this.description = item.description;
  }

  addDetails(list) {
    this.detailList = [];
    list.forEach((detail) => {
      this.detailList.push(new ProductionDetail(detail));
    });
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id);
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1);
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex((item) => item.id === Id);
  }

  findItem(Id) {
    return this.detailList.find((item) => item.id === Id);
  }

  get detailIsEmpty() {
    return this.detailList.length === 0;
  }
}

export class ProductionDetail {
  constructor(detail) {
    this.id = detail.id;
    this.productionHeaderId = detail.productionHeaderId;
    this.headerCode = detail.headerCode;
    this.productionStockItemId = detail.productionStockItemId;
    this.productionStockItemName = detail.productionStockItemName;
    this.unitId = detail.unitId;
    this.unitName = store.getters["Units/findUnit"](detail.unitId).name;
    this.unitCode = detail.unitCode;
    this.quantity = detail.quantity;
    this.plannedQuantity = detail.plannedQuantity;
    this.baseUnitPrice = detail.baseUnitPrice;
    this.totalAmount = detail.totalAmount;
    this.stockOnHandQuantity = detail.stockOnHandQuantity;
    this.expirationDateControlIsEnabled = detail.expirationDateControlIsEnabled;
    this.isChecked = false;
  }
}
