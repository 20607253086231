// Production

import Production from '../index'
import ProductionDocumentDetail from '../production-detail-document'
import ProductionForm from '../popup/header-form'
import ProductionRemove from '../popup/header-remove'

import ProductioDetailForm from '../popup/detail-form'
import ProductionDetailRemove from '../popup/detail-remove'

// Production Planing
import ProductionPlaning from '../production-planing'
import ProductionPlaningHeaderForm from '../popup/planing-header-form'
import ProductionPlaningDetailForm from '../popup/planing-detail-form'
import ProductionPlaningHeaderRemove from '../popup/planing-header-remove'
import ProductionPlaningDetailRemove from '../popup/planing-detail-remove'

// RawMaterialPlanning
import RawMaterialPlanning from '../raw-material-planning'
import RawMaterialPlanningHeaderForm from '../popup/raw-material-planning-header-form'
import RawMaterialPlanningHeaderRemove from '../popup/raw-material-planning-header-remove'
import RawMaterialPlanningDetailForm from '../popup/raw-material-planning-detail-form'
import RawMaterialPlanningDetailRemove from '../popup/raw-material-planning-detail-remove'
import RawMaterialDetailRemove from '../popup/raw-material-detail-remove'

export default [
  {
    name: 'Production',
    component: Production,
    path: '/production',
    meta: {
      slug: 'production',
      category: 'Cost',
      tabPermissionKey: 'Production',
      main_menu: true,
      page: 'production'
    },
    children: [
      {
        name: 'NewProduction',
        component: ProductionForm,
        path: '/production/new'
      },
      {
        name: 'EditProduction',
        component: ProductionForm,
        path: '/production/edit/:id',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveProduction',
        component: ProductionRemove,
        path: '/production/remove/:id'
      },
      {
        name: 'NewProductionDetail',
        component: ProductioDetailForm,
        path: '/production/detail/new/:header'
      },
      {
        name: 'EditProductionDetail',
        component: ProductioDetailForm,
        path: '/production/detail/edit/:header/:id',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveProductionDetail',
        component: ProductionDetailRemove,
        path: '/production/detail/remove/:id/:detailId'
      }
    ]
  },
  {
    name: 'ProductionPlaning',
    path: '/production/production-planing',
    component: ProductionPlaning,
    meta: {
      page: 'production',
      tabPermissionKey: 'ProductionPlanningMenu'
    },
    children: [
      {
        name: 'NewProductionPlaning',
        path: 'new',
        component: ProductionPlaningHeaderForm
      },
      {
        name: 'EditProductionPlaning',
        component: ProductionPlaningHeaderForm,
        path: 'edit/:id',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'NewProductionPlaningDetail',
        path: 'detail/new/:headerId',
        component: ProductionPlaningDetailForm
      },
      {
        name: 'EditProductionPlaningDetail',
        path: 'detail/edit/:id',
        component: ProductionPlaningDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveProductionPlaningHeader',
        path: '/remove/:id',
        component: ProductionPlaningHeaderRemove
      },
      {
        name: 'RemoveProductionPlaningDetail',
        path: 'detail/remove/:id/:detailId',
        component: ProductionPlaningDetailRemove
      }
    ]
  },

  {
    name: 'RawMaterialPlanning',
    path: '/production/raw-material-planning',
    component: RawMaterialPlanning,
    meta: {
      page: 'production',
      tabPermissionKey: 'RawMaterialPlanningMenu'
    },
    children: [
      {
        name: 'NewRawMaterialPlanningHeader',
        path: 'new',
        component: RawMaterialPlanningHeaderForm
      },
      {
        name: 'NewRawMaterialPlanningDetail',
        path: 'detail/new/:headerId',
        component: RawMaterialPlanningDetailForm
      },
      {
        name: 'EditRawMaterialPlanning',
        component: RawMaterialPlanningHeaderForm,
        path: 'edit/:id',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveRawMaterialPlanningHeader',
        path: 'remove/:id',
        component: RawMaterialPlanningHeaderRemove
      },
      {
        name: 'EditRawMaterialPlanningDetail',
        component: RawMaterialPlanningDetailForm,
        path: 'detail/edit/:detailId',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveRawMaterialPlanningDetail',
        path: 'detail/remove/:id/:detailId',
        component: RawMaterialPlanningDetailRemove
      },
      {
        name: 'RemoveRawMaterialDetail',
        path: 'raw-detail/remove/:headerId',
        component: RawMaterialDetailRemove
      }
    ]
  },
  {
    path: '/production/detail/:id/:documentNumber',
    name: 'ProductionDocumentDetail',
    component: ProductionDocumentDetail,
    meta: {
      tabPermissionKey: 'Production',
      page: 'production'
    }
  }
]
