import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import RawMaterialPlanning from '../model/raw-material-planning'

export default {
  namespaced: true,

  state: {
    RawMaterialHeaderList: new RawMaterialPlanning(),
    Page: {
      ...Page
    }
  },

  getters: {
    Page: state => PageGetters({ state, stateName: 'Page' }),
    RawMaterialHeaderList: state => state.RawMaterialHeaderList.list,
    isEmptyRawMaterialList: state => state.RawMaterialHeaderList.list.length === 0
  },

  actions: {

    async createRawMaterialPlanningHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createRawMaterialPlanningHeader',
        methodName: 'createRawMaterialPlanningHeader',
        method: () => {
          return HTTP.post('RawMaterialPlanning/header', payload)
        },
        success: result => result
      })
      return results
    },

    async finalizeRawMaterialPlanning({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'finalizeRawMaterialPlanning',
        methodName: 'finalizeRawMaterialPlanning',
        method: () => {
          return HTTP.put('RawMaterialPlanning/finalize', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchRequestedItemsForList({ commit }, payload) {
      const results = await requestHandler({
        title: 'fetchRequestedItemsForList',
        methodName: 'fetchRequestedItemsForList',
        method: () => {
          return HTTP.get('RawMaterialPlanning/store/requested', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async updateRawMaterialPlanningHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateRawMaterialPlanningHeader',
        methodName: 'updateRawMaterialPlanningHeader',
        method: () => {
          return HTTP.put('RawMaterialPlanning/header', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchRawMaterialPlanningHeader({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchRawMaterialPlanningHeader',
        methodName: 'fetchRawMaterialPlanningHeader',
        method: () => {
          return HTTP.get('RawMaterialPlanning/header', {params: { Id }})
        },
        success: result => result
      })
      return results
    },

    async fetchRawMaterialPlanningHeaderList({ state, commit }, params) {
      const PageNumber = getPaginationNumber({ selectedPage: params.pageNumber, currentPage: state.Page.number })
      const payload = {
        ...params,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'fetchRawMaterialPlanningHeaderList',
        methodName: 'fetchRawMaterialPlanningHeaderList',
        method: () => {
          return HTTP.post('/RawMaterialPlanning/header/all', payload)
        },
        success: result => {
          commit('SET_RAW_MATERIAL_HEADER_LIST', result.data)
          return result
        }
      })
      return results
    },

    async deleteRawMaterialPlanning({ commit }, Id) {
      let results = await requestHandler({
        title: 'deleteRawMaterialPlanning',
        methodName: 'deleteRawMaterialPlanning',
        method: () => {
          return HTTP.delete('RawMaterialPlanning', { params: { Id } })
        },
        success: result => result
      })
      return results
    },

    async deleteZeroQuantityProductItems({ commit }, RawMaterialPlanningHeaderId) {
      let results = await requestHandler({
        title: 'deleteZeroQuantityProductItems',
        methodName: 'deleteZeroQuantityProductItems',
        method: () => {
          return HTTP.delete('RawMaterialPlanning/zeroquantity', { params: { RawMaterialPlanningHeaderId } })
        },
        success: result => result
      })
      return results
    },

    async deleteZeroQuantityStockItems({ commit }, RawMaterialPlanningHeaderId) {
      let results = await requestHandler({
        title: 'deleteZeroQuantityStockItems',
        methodName: 'deleteZeroQuantityStockItems',
        method: () => {
          return HTTP.delete('RawMaterialPlanning/rawmaterial/zeroquantity', { params: { RawMaterialPlanningHeaderId } })
        },
        success: result => result
      })
      return results
    },

    async deleteRawMaterialPlanningDetail({ commit }, Id) {
      let results = await requestHandler({
        title: 'deleteRawMaterialPlanningDetail',
        methodName: 'deleteRawMaterialPlanningDetail',
        method: () => {
          return HTTP.delete('RawMaterialPlanning/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchRawMaterialPlanningDetailList({ commit }, RawMaterialPlanningHeaderId) {
      const results = await requestHandler({
        title: 'fetchRawMaterialPlanningDetailList',
        methodName: 'fetchRawMaterialPlanningDetailList',
        method: () => {
          return HTTP.get('RawMaterialPlanning/detail/all', {
            params: { RawMaterialPlanningHeaderId }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchRawMaterialDetailList({ commit }, RawMaterialPlanningHeaderId) {
      const results = await requestHandler({
        title: 'fetchRawMaterialDetailList',
        methodName: 'fetchRawMaterialDetailList',
        method: () => {
          return HTTP.get('RawMaterialPlanning/rawmaterialdetail/all', {
            params: { RawMaterialPlanningHeaderId }
          })
        },
        success: result => result
      })
      return results
    },

    async createRawMaterialPlanningDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createRawMaterialPlanningDetail',
        methodName: 'createRawMaterialPlanningDetail',
        method: () => {
          return HTTP.post('RawMaterialPlanning/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async updateRawMaterialPlanningDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateRawMaterialPlanningDetail',
        methodName: 'updateRawMaterialPlanningDetail',
        method: () => {
          return HTTP.put('RawMaterialPlanning/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchRawMaterialPlanningDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchRawMaterialPlanningDetail',
        methodName: 'fetchRawMaterialPlanningDetail',
        method: () => {
          return HTTP.get('RawMaterialPlanning/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async deleteRawMaterialDetail({ commit }, payload) {
      let results = await requestHandler({
        title: 'deleteRawMaterialPlanningDetail',
        methodName: 'deleteRawMaterialPlanningDetail',
        method: () => {
          return HTTP.delete('RawMaterialPlanning/rawmaterialdetail', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async updateRawMaterialDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateRawMaterialDetail',
        methodName: 'updateRawMaterialDetail',
        method: () => {
          return HTTP.put('RawMaterialPlanning/rawmaterialdetail', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_RAW_MATERIAL_HEADER_LIST(state, data) {
      state.RawMaterialHeaderList.addItems(data.rawMaterialPlanningHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    HIDE_DETAIL(state, rawMaterialPlanningHeader) {
      rawMaterialPlanningHeader.showDetail = false
    },

    SHOW_DETAIL(state, data) {
      let production = state.RawMaterialHeaderList.findItem(data.RawMaterialPlaningHeader.id)
      production.addDetails(data.RawMaterialPlanningDetailList.map(item => {
        return {
          ...item,
          headerCode: production.code,
          storeName: production.storeName,
          storeId: production.storeId,
          planningDate: production.planningDate,
          planningName: production.name,
          status: production.status
        }
      }))
      production.showDetail = true
      production.isRawMaterial = false
    },

    SHOW_DETAIL_RAW_LIST(state, data) {
      let production = state.RawMaterialHeaderList.findItem(data.RawMaterialPlaningHeader.id)
      production.addDetails(data.RawMaterialPlanningDetailList.map(item => {
        return {
          ...item,
          headerCode: production.code,
          storeName: production.storeName,
          storeId: production.storeId,
          planningDate: production.planningDate,
          planningName: production.name,
          rawMaterialPlanningHeaderId: data.RawMaterialPlaningHeader.id,
          status: production.status
        }
      }))
      production.showDetail = true
      production.isRawMaterial = true
    },

    UPDATE_RAW_DETAIL_LIST(state, data) {
      let rawMaterial = state.RawMaterialHeaderList.findItem(data.rawMaterialPlanningHeaderId)
      rawMaterial.updateRawListItem(data)
    }
  }
}
