import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import ProductionPlanning from '../model/production-planning'
export default {
  namespaced: true,

  state: {
    ProductionPlaning: new ProductionPlanning(),
    Page: {
      ...Page
    }
  },

  /**
  *
  **/

  getters: {
    ProductionPlaning: state => state.ProductionPlaning.list,
    isEmptyPlaningList: state => state.ProductionPlaning.list.length === 0,
    Page: state => PageGetters({ state, stateName: 'Page' })
  },

  /**
  *
  **/

  actions: {

    async fetchRequestedItemsForList({ commit }, payload) {
      const results = await requestHandler({
        title: 'fetchRequestedItemsForList',
        methodName: 'fetchRequestedItemsForList',
        method: () => {
          return HTTP.get('ProductionPlanning/store/requested', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async createProductionPlanningHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createProductionPlanningHeader',
        methodName: 'createProductionPlanningHeader',
        method: () => {
          return HTTP.post('ProductionPlanning/header', payload)
        },
        success: result => result
      })
      return results
    },

    async updateProductionPlanningHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateProductionPlanningHeader',
        methodName: 'updateProductionPlanningHeader',
        method: () => {
          return HTTP.put('ProductionPlanning/header', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchProductionPlanningHeader({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.page, currentPage: state.Page.number })
      const params = {
        pageSize: payload.pageSize,
        pageNumber: PageNumber,
        showDrafts: payload.showDrafts
      }
      const results = await requestHandler({
        title: 'fetchProductionPlanningHeader',
        methodName: 'fetchProductionPlanningHeader',
        method: () => {
          return HTTP.post('ProductionPlanning/header/all', params)
        },
        success: result => {
          commit('SET_PRODUCTION_PLANNING_LIST', result.data)
          return result
        }
      })
      return results
    },

    async searchProductPlanning({ commit }, payload) {
      const results = await requestHandler({
        title: 'searchProductPlanning',
        methodName: 'searchProductPlanning',
        method: () => {
          return HTTP.get('ProductionPlanning/header/filter', {
            params: { ...payload }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchProductPlanningHeader({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchProductPlanningHeader',
        methodName: 'fetchProductPlanningHeader',
        method: () => {
          return HTTP.get('ProductionPlanning/header', {params: { Id }})
        },
        success: result => result
      })
      return results
    },

    async fetchProductPlanningDetail({ commit }, ProductionPlanningHeaderId) {
      const results = await requestHandler({
        title: 'fetchProductPlanningDetail',
        methodName: 'fetchProductPlanningDetail',
        method: () => {
          return HTTP.get('ProductionPlanning/detail/all', {
            params: { ProductionPlanningHeaderId }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchProductPlanningDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchProductPlanningDetailInfo',
        methodName: 'fetchProductPlanningDetailInfo',
        method: () => {
          return HTTP.get('ProductionPlanning/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async createProductionPlanningDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createProductionPlanningDetail',
        methodName: 'createProductionPlanningDetail',
        method: () => {
          return HTTP.post('ProductionPlanning/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async updateProductionPlanningDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateProductionPlanningDetail',
        methodName: 'updateProductionPlanningDetail',
        method: () => {
          return HTTP.put('ProductionPlanning/detail', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteProductionPlanning({commit, dispatch}, Id) {
      let results = await requestHandler({
        title: 'deleteProductionPlanning',
        methodName: 'deleteProductionPlanning',
        method: () => {
          return HTTP.delete('ProductionPlanning', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },

    async deleteProductionPlanningDetail({ commit }, Id) {
      let results = await requestHandler({
        title: 'deleteProductionPlanningDetail',
        methodName: 'deleteProductionPlanningDetail',
        method: () => {
          return HTTP.delete('ProductionPlanning/detail', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },

    async makeFinalizeProductionPlanning({ commit }, Id) {
      const results = await requestHandler({
        title: 'makeFinalizeProductionPlanning',
        methodName: 'makeFinalizeProductionPlanning',
        method: () => {
          return HTTP.put('ProductionPlanning/finalize', { Id })
        },
        success: result => result
      })
      return results
    }

  },

  /**
  *
  **/

  mutations: {

    SET_PRODUCTION_PLANNING_LIST(state, data) {
      state.ProductionPlaning.addItems(data.productionPlanningHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    //  DETAIL STATUS MUTATION
    HIDE_DETAIL(state, productionPlaningHeader) {
      productionPlaningHeader.showDetail = false
    },

    UPDATE_TRANSFER_ITEM(state, item) {
      state.ProductionPlaning.updateItem(item)
    },

    HIDE_OTHERS_DETAILS(state, productionPlaningHeader) {
      const others = state.ProductionPlaning.list.filter(production => production.id !== productionPlaningHeader.id)
      others.forEach(otherProductionPlaning => {
        otherProductionPlaning.showDetail = false
      })
    },

    SHOW_DETAIL(state, data) {
      let production = state.ProductionPlaning.findItem(data.productionPlaningHeader.id)
      production.addDetails(data.productionPlanningDetailList.map(item => {
        return {
          ...item,
          headerCode: data.productionPlaningHeader.code,
          storeName: data.productionPlaningHeader.storeName,
          storeId: data.productionPlaningHeader.storeId,
          planningDate: data.productionPlaningHeader.planningDate,
          planningName: data.productionPlaningHeader.name
        }
      }))
      production.showDetail = true
    }

  }
}
