<template lang="pug">
Popup
    template(slot="title")
      template(v-if="isEdit") {{ $t('Global.editData', { field: $t('Dictionary.Production') }) }}
      template(v-else) {{ $t('Global.addData', { field: $t('Dictionary.Production') }) }}

    template(slot="content")
      form.Form(
        id="production-header-form",
        @submit.prevent="onSubmitForm"
      )

        .Form-item.required
          label.Form-item-label
            | {{ $t('Dictionary.Store') }}
          .control.form-item-select
            custom-search(
              ref="firstInput"
              component-name="production-header-store-name"
              name="storeName",
              v-model="form.storeId"
              :data-vv-as="$t('Dictionary.Store')",
              v-validate="'required|is_not:-1'",
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              :disabled="isEdit || isGettingStores"
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :loading="isSearchStoreLists"
              :placeholder="$t('Production.Header_Popup_StoreName_Placeholder')"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )
        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Date') }}
          .control.form-item-select
            CustomDatepicker(
              id="production-header-form-datepicker"
              name="productionDate",
              :data-vv-as="$t('Dictionary.Date') ",
              v-model="form.productionDate",
              :class="{ 'is-danger': veeErrors.has(`productionDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabled="isEdit"
              :disabledStartDate="disableDate")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('productionDate')"
          ) {{ veeErrors.first('productionDate') }}

        .Form-item(v-if="!isEdit" :class="isOnlyListUsage ? 'required' : '' ")
          label.Form-item-label {{ $t('Dictionary.List') }}
          .control
            custom-search(
              ref="listInput"
              component-name="production-header-form-list"
              name="production-header-form-list"
              v-model="form.list"
              :data-vv-as="$t('Dictionary.List')"
              :on-search="getOptionsList"
              :options="selectOptionsList"
              v-validate="{required: isOnlyListUsage}"
              :error="veeErrors.has('production-header-form-list')"
              label="name"
              :loading="isSearchProductionListHeader"
              :searchAll="true"
              :placeholder="$t('Global.search_Placeholder')"
            )
              template(slot="no-options") {{ $t('Global.search_NoResults') }}

          showValidateError(
            v-show="veeErrors.has('production-header-form-list')"
            :errorName="veeErrors.first('production-header-form-list')"
          )

          .selected-items
            Button(
              v-for="(item,index) in lists",
              :key="index",
              seashell,
              :id="`selected-product-item-${index}`"
              @click="removeListItem(item)"
            )
              span {{ item.name }}
              Icon.m-left-5(
                name="icon-popup-close"
                :id="`selected-stock-item-delete-${index}`"
              )

        .Form-item(v-if="!isEdit")
          MultiSearchSelect(
            v-model="planningList",
            searchAction="ProductionPlaning/searchProductPlanning",
            searchResultKey="productionPlanningHeaderList",
            :formLabel="$t('ProductionPlaning.ProductionPlaning')",
            moduleName="production",
            :isSearchListResetInEveryAction="true",
            searchLabel="nameWithDate",
            :hasSeachPayload="true",
            :searchPayload="productionPlanninglistSearchPayload"
          )

        .Form-item
          label.Form-item-label {{ $t('Production.formFieldDescription') }}
          .control
            customTextareaInput(
              ref="descriptionInput"
              v-model="form.description"
              v-validate="'max:128'"
              name="description"
              :data-vv-as="$t('Production.formFieldDescription')"
              :error="veeErrors.has('description')"
              rows="3"
            )

            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        id="button-production-add-header",
        form="production-header-form",
        :disabled="isCreating",
        :isLoading="isCreating"
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('Dictionary.Production') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('Dictionary.Production') }) }}
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import MultiSearchSelect from '@/view/global/multi-search-select'
import moment from 'moment'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ProductionForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    MultiSearchSelect
  },

  data () {
    return {
      lists: [],
      selectOptionsList: [],
      selectMiniStoreList: [],
      form: {
        storeId: '-1',
        productionDate: new Date(),
        list: null,
        description: null
      },
      planningList: [],
      disableDate: {
        from: new Date()
      },
      isOnlyListUsage: false
    }
  },

  computed: {
    //
    ...mapGetters('Stores', ['storeList']),
    ...mapGetters('Settings', ['UserInfo', 'permissions']),

    storeListItems () {
      return this.isEdit ? this.storeList : this.storeList.filter(i => i.canProduction)
    },

    isSearchProductionListHeader () {
      return this.$wait.is('searchProductionListHeader')
    },

    isCreating () {
      return this.$wait.is(['createProductionHeader', 'updateProductionHeader', 'onSubmitFormHeaderForm', 'getStoreList'])
    },

    isGettingStores () {
      return this.$wait.is(['getStoreList'])
    },

    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    productionPlanninglistSearchPayload () {
      return {
        storeId: this.form.storeId.id
      }
    },

    isEdit () {
      return this.status === 'edit'
    }
  },

  methods: {

    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Stores', ['getStoreList','fetchStoreByName']),
    ...mapActions('ListsProduction', ['searchProductionListHeader']),
    ...mapActions('Production', [
      'createProductionHeader',
      'updateProductionHeader',
      'fetchProduct'
    ]),
    ...mapMutations('Production', [
      'SHOW_DETAIL',
      'HIDE_OTHERS_DETAILS'
    ]),

    setForm (item) {
      this.form.storeId = { id: item.storeId, name: item.storeName }
      this.form.productionDate = item.productionDate
      this.form.description = item.description
    },

    getOptionsList (search) {
      this.searchProductionListHeader({ Text: search })
        .then(res => {
          if (res) {
            this.selectOptionsList = res.data.productionListHeaderList
          }
        })
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            const canProductionList = res.data.items.filter(obj1 => 
              this.storeListItems.some(obj2 => obj2.id === obj1.id)
            );
            this.selectMiniStoreList = canProductionList
          }
        })
    },
    inputSelection (data) {
      this.form.storeId.id = data.id
      this.form.storeId.name = data.name
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    removeListItem (item) {
      this.lists.splice(this.lists.indexOf(item), 1)
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    focusFirstInput () {
      this.isEdit ? this.$refs.descriptionInput.focusCustomTextareaInput() : this.$refs.listInput.$refs.search.focus()
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) await this.editProductionHeader()
        else await this.newProductionHeader()
      })
    }, 'onSubmitFormHeaderForm'),

    async newProductionHeader () {
      const res = await this.createProductionHeader({
        storeId: this.form.storeId.id,
        productionDate: moment(this.form.productionDate).format('YYYY-MM-DD'),
        productionListHeaderIds: this.lists.map(_ => _.id),
        productionPlanningHeaderIds: this.planningList.map(_ => _.id),
        description: this.form.description
      })
      if (res) {
        this.$emit('showDrafts')
        const message = this.$t('Global.successCreateMessage', { field: this.$t('Dictionary.Production') })
        this.notifyShow({ message })
        this.closePopup()
        const allList = this.$emit('getProductionsList')
        if (allList) {
          const list = await this.fetchProduct(res.data.id)
          if (list) {
            if (this.lists.length > 0 || this.planningList.length > 0) {
              this.SHOW_DETAIL(list.data)
            } else {
              const header = {
                id: res.data.id,
                code: res.data.code,
                storeId: list.data.productionHeader.storeId
              }
              this.$router.push({
                params: {
                  header
                },
                name: 'NewProductionDetail'
              })
            }
          }
        }
      }
    },

    async editProductionHeader () {
      const payload = {
        id: this.$route.params.id,
        description: this.form.description
      }
      await this.updateProductionHeader(payload)
        .then(res => {
          if (!res) {
            return
          }
          const message = this.$t('Global.successUpdateMessage', { field: this.$t('Dictionary.Production') })
          this.notifyShow({ message })
          this.$emit('getProductionsList')
          this.closePopup()
        })
    }
  },

  watch: {
    async 'form.list' (value) {
      if (!value || this.lists.find(_ => _.id === value.id)) return
      this.lists.push(value)
    },
    lists (val) {
      if (val.length === 0) {
        this.form.list = null
      }
    },

    'form.storeId.id' (val) {
      if (val === '-1') return
      this.planningList = []
    }
  },
  async mounted () {
    await this.getStoreList({pageNumber: 1, pageSize: 9999})
    if (this.isEdit) {
      if (this.$route.params.item) this.setForm(this.$route.params.item)
      else {
        this.fetchProduct(this.$route.params.id)
          .then(res => {
            this.setForm(res.data.productionHeader)
          })
      }
    } else {
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
      const isProductionStoreExist = this.storeListItems.find(i => i.id === this.UserInfo.defaultStoreId)
      if (isProductionStoreExist) this.form.storeId = { id: this.UserInfo.defaultStoreId, name: this.UserInfo.defaultStoreName }
    }
    this.focusFirstInput()
  }
}
</script>

<style lang="scss" scoped>

  .selected-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .btn {
      width: auto;
      height: 34px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
</style>
