var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { isOpen: true }, on: { closeModal: _vm.closeModal } },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("Dictionary.DocNo")) +
                ": " +
                _vm._s(_vm.productionHeader?.code)
            ),
          ]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("Dictionary.Date")) +
                ": " +
                _vm._s(
                  _vm.getFormattedProductionDate(
                    _vm.productionHeader.productionDate
                  )
                )
            ),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.data.length
            ? _c("NestedTable", {
                attrs: {
                  fields: _vm.fields,
                  detailFields: _vm.detailFields,
                  data: _vm.data,
                  newRow: _vm.newRow,
                  isDetailDataLoading: _vm.isShelfListLoading,
                  activateNewRow: _vm.activateNewRow,
                  showDetailWithEmptyData: true,
                  actions: ["export"],
                  detailActions: ["export"],
                },
                on: {
                  showExportPopup: _vm.showBatchExportPopup,
                  addNewData: _vm.addNewRow,
                  showDetailAction: _vm.showDetail,
                  showDetailExportPopup: _vm.showDetailExportPopup,
                },
              })
            : _c("EmptyState", {
                attrs: {
                  title: _vm.$t("Despatches.emptyState_Title_Batch", {
                    field: _vm.$t("Dictionary.Batch"),
                  }),
                  description: _vm.$t("Global.emptyState_Description_Batch", {
                    field: _vm.$t("Dictionary.Batch"),
                  }),
                },
              }),
          _c("portal", {
            attrs: { to: "discard" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("Button", {
                        attrs: {
                          justIcon: true,
                          clearVariant: true,
                          iconName: "circle-remove",
                          iconClass: "circle-remove",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeBatch(detail.row, _vm.item)
                          },
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "saveBatch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("Button", {
                        attrs: {
                          justIcon: true,
                          clearVariant: true,
                          iconName: "icon-btn-success",
                          iconClass: "icon-btn-success",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.saveBatch(detail.row, _vm.item)
                          },
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "quantity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customNumberInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true, greater_than: 0 },
                              expression: "{required: true, greater_than: 0}",
                            },
                          ],
                          attrs: {
                            name: `quantity-${detail.rowIndex}-${detail.row?.id}`,
                            "data-vv-as": _vm.$t(
                              "Despatches.detail_TableColumn_Quantity"
                            ),
                            id: "input-batch-quantity",
                            error: _vm.veeErrors.has(
                              `quantity-${detail.rowIndex}-${detail.row?.id}`
                            ),
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changeValue(
                                $event,
                                detail.row,
                                "quantity"
                              )
                            },
                          },
                          model: {
                            value: detail.row.id
                              ? detail.row.quantity
                              : _vm.item.quantity,
                            callback: function ($$v) {
                              _vm.$set(
                                detail.row.id ? detail.row.quantity : _vm.item,
                                "quantity",
                                $$v
                              )
                            },
                            expression:
                              "detail.row.id ? detail.row.quantity : item.quantity",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has(
                                `quantity-${detail.rowIndex}-${detail.row?.id}`
                              ),
                              expression:
                                "veeErrors.has(`quantity-${detail.rowIndex}-${detail.row?.id}`)",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first(
                              `quantity-${detail.rowIndex}-${detail.row?.id}`
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "shelfLife" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("customNumberInput", {
                        attrs: {
                          name: `shelf-life-${detail.rowIndex}-${detail.row?.id}`,
                          "data-vv-as": _vm.$t("Despatches.shelfLife"),
                          id: "input-batch-shelf-life",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.changeValue(
                              $event,
                              detail.row,
                              "shelfLife"
                            )
                          },
                        },
                        model: {
                          value: detail.row.id
                            ? detail.row.shelfLife
                            : _vm.item.shelfLife,
                          callback: function ($$v) {
                            _vm.$set(
                              detail.row.id ? detail.row.shelfLife : _vm.item,
                              "shelfLife",
                              $$v
                            )
                          },
                          expression:
                            "detail.row.id ? detail.row.shelfLife : item.shelfLife",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "productionDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("CustomDatepicker", {
                        class: {
                          "is-danger": _vm.veeErrors.has("productionDate"),
                        },
                        attrs: {
                          id: "batch-production-date",
                          name: "productionDate",
                          "data-vv-as": _vm.$t("Despatches.productionDate"),
                          useFilterReset: true,
                          pickerType: "manuel",
                          inputIconRight: "icon-datepicker",
                          inputIcon: "",
                        },
                        on: {
                          resetDateFilter: function ($event) {
                            return _vm.resetDate(detail.row, "productionDate")
                          },
                        },
                        model: {
                          value: _vm.selectedProductionDate,
                          callback: function ($$v) {
                            _vm.selectedProductionDate = $$v
                          },
                          expression: "selectedProductionDate",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "expirationDate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("CustomDatepicker", {
                        class: {
                          "is-danger": _vm.veeErrors.has("expirationDate"),
                        },
                        attrs: {
                          id: "batch-expiration-date",
                          name: "expirationDate",
                          "data-vv-as": _vm.$t("Despatches.expirationDate"),
                          useFilterReset: true,
                          pickerType: "manuel",
                          inputIconRight: "icon-datepicker",
                          inputIcon: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.selectDate($event, detail.row)
                          },
                          resetDateFilter: function ($event) {
                            return _vm.resetDate(detail.row, "expirationDate")
                          },
                        },
                        model: {
                          value: detail.row.id
                            ? detail.row.expirationDate
                            : _vm.item.expirationDate,
                          callback: function ($$v) {
                            _vm.$set(
                              detail.row.id
                                ? detail.row.expirationDate
                                : _vm.item,
                              "expirationDate",
                              $$v
                            )
                          },
                          expression:
                            "detail.row.id ? detail.row.expirationDate : item.expirationDate",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "degree" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: detail.row.id
                                ? detail.row.degree
                                : _vm.item.degree,
                              expression:
                                "detail.row.id ? detail.row.degree : item.degree",
                            },
                          ],
                          staticClass: "input-wrapper-txt",
                          attrs: { type: "number" },
                          domProps: {
                            value: detail.row.id
                              ? detail.row.degree
                              : _vm.item.degree,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                detail.row.id ? detail.row.degree : _vm.item,
                                "degree",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has(
                              `degree-${detail.rowIndex}-${detail.row?.id}`
                            ),
                            expression:
                              "veeErrors.has(`degree-${detail.rowIndex}-${detail.row?.id}`)",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            `degree-${detail.rowIndex}-${detail.row?.id}`
                          ),
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "lotNumber" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c("div", {}, [
                    _c("span", [
                      _vm._v(
                        _vm._s(detail.row.id ? detail.row.lotNumber : "-")
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "warehouseTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c("div", {}, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: detail.row.id
                            ? _vm.warehouseTimeData[detail.rowIndex]
                            : _vm.item.warehouseTime,
                          expression:
                            "detail.row.id ? warehouseTimeData[detail.rowIndex] : item.warehouseTime",
                        },
                      ],
                      attrs: { type: "time" },
                      domProps: {
                        value: detail.row.id
                          ? _vm.warehouseTimeData[detail.rowIndex]
                          : _vm.item.warehouseTime,
                      },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              detail.row.id
                                ? _vm.warehouseTimeData[detail.rowIndex]
                                : _vm.item,
                              "warehouseTime",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.changeTime(
                              _vm.warehouseTimeData[detail.rowIndex],
                              detail.row,
                              "warehouseTime"
                            )
                          },
                        ],
                      },
                    }),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "shipmentTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c("div", {}, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: detail.row.id
                            ? _vm.shipmentTimeData[detail.rowIndex]
                            : _vm.item.shipmentTime,
                          expression:
                            "detail.row.id ? shipmentTimeData[detail.rowIndex] : item.shipmentTime",
                        },
                      ],
                      attrs: { type: "time" },
                      domProps: {
                        value: detail.row.id
                          ? _vm.shipmentTimeData[detail.rowIndex]
                          : _vm.item.shipmentTime,
                      },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              detail.row.id
                                ? _vm.shipmentTimeData[detail.rowIndex]
                                : _vm.item,
                              "shipmentTime",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.changeTime(
                              _vm.shipmentTimeData[detail.rowIndex],
                              detail.row,
                              "shipmentTime"
                            )
                          },
                        ],
                      },
                    }),
                  ])
                },
              },
            ]),
          }),
          _c("portal", {
            attrs: { to: "shelfStorageLocationList" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (detail) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("customSelect", {
                        attrs: {
                          selectedEmitValue: "id",
                          defaultTitle: _vm.$t(
                            "Despatches.selectShelfStorageLocation"
                          ),
                          optionData: _vm.ShelfListFromStore,
                          optionTitle: "name",
                          componentName: "shelf-storage-location-select",
                        },
                        on: {
                          inputSelected: function ($event) {
                            return _vm.changeValue(
                              $event,
                              detail.row,
                              "shelfStorageLocationListHeaderId"
                            )
                          },
                        },
                        model: {
                          value: detail.row.id
                            ? detail.row.shelfStorageLocationListHeaderId
                            : _vm.item.shelfStorageLocationListHeaderId,
                          callback: function ($$v) {
                            _vm.$set(
                              detail.row.id
                                ? detail.row.shelfStorageLocationListHeaderId
                                : _vm.item,
                              "shelfStorageLocationListHeaderId",
                              $$v
                            )
                          },
                          expression:
                            "detail.row.id ? detail.row.shelfStorageLocationListHeaderId : item.shelfStorageLocationListHeaderId",
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _vm.isExportPopupVisible
            ? _c("exportPopup", {
                ref: "exportPopupTemplate",
                attrs: {
                  templateId: _vm.templateId,
                  templateType: _vm.templateType,
                },
              })
            : _vm._e(),
          _vm.showBatchAlert
            ? _c("Alert", {
                attrs: {
                  title: _vm.$t("Transfer.warning_batch"),
                  hasCancelButton: false,
                  description: _vm.$t("Despatches.description_batch_warning"),
                  okButton: _vm.$t("Global.okay"),
                  icon: "icon-global-notify",
                  loading: "alertForm",
                },
                on: {
                  delegateOnAlertOk: function ($event) {
                    _vm.showBatchAlert = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "Button",
              {
                staticClass: "m-right-10",
                attrs: {
                  id: "production-batch-detail-cancel",
                  size: "small",
                  secondary: "",
                  inline: "",
                },
                on: { click: _vm.closeModal },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("Global.Cancel")))])]
            ),
            _c(
              "Button",
              {
                attrs: {
                  id: "production-batch-detail-save",
                  size: "small",
                  primary: "",
                  inline: "",
                },
                on: { click: _vm.validateAllBatch },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("Global.Save")))])]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }