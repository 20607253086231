export function getProductionsHeaderFields($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.DocNo')
      },
      get title() {
        return $t('Dictionary.DocNo')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Store')
      },
      get title() {
        return $t('Dictionary.Store')
      }
    },
    {
      name: 'productionDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Date')
      },
      get title() {
        return $t('Dictionary.Date')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    },
    {
      name: 'totalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.total_amount')
      },
      get title() {
        return $t('Global.total_amount')
      }
    }
  ]
}

export function getProductionsDetailFields($t, $getConst) {
  return [
    {
      name: 'productionStockItemName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.ProductItem')
      },
      get title() {
        return $t('Dictionary.ProductItem')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Production.StockOnHandQuantity')
      },
      get title() {
        return $t('Dictionary.StockOnHandQuantity')
      }
    },
    {
      name: 'plannedQuantity',
      is_visible: true,
      get short_title() {
        return $t('Production.plannedQuantity')
      },
      get title() {
        return $t('Production.plannedQuantity')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      editable: true,
      validationRules: {decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')},
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Quantity')
      },
      get title() {
        return $t('Dictionary.Quantity')
      }
    },
    {
      name: 'unitName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Unit')
      },
      get title() {
        return $t('Dictionary.Unit')
      }
    },
    {
      name: 'baseUnitPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.waste_cost')
      },
      get title() {
        return $t('Dictionary.waste_cost')
      }
    },
    {
      name: 'totalAmount',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.amount')
      },
      get title() {
        return $t('Global.amount')
      }
    }
  ]
}
