<template lang="pug">
 Popup
    template(slot="title")
      div {{ formTitle }}

    template(slot="detail")
      div.m-top-25
       h4.m-bottom-10 {{ $t('Dictionary.DocNo') }}
       span.ff-mono(v-if="headerInfo") {{ headerInfo.code }}

    template(slot="content")
      form.Form(
       id="Production-Detail-Form",
       @submit.prevent="onSubmitForm"
      )
        .Form-item.Form-item-info(v-if="headerInfo")
          .field
            .name {{ $t('Dictionary.Store') }} :
            .value {{ headerInfo.storeName }}

          .field
            .name {{ $t('Dictionary.Date') }} :
            .value {{ headerInfo.planningDate | dateFormat }}

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.ProductItem') }}
          .control
            custom-search(
              component-name="production-form-detail-productionItem"
              name="productItem"
              v-model="form.productItem"
              :data-vv-as="$t('Dictionary.ProductItem')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('productItem') }"
              ref="focusableInput"
              :on-search="getOptions"
              :options="selectOptions"
              :disabled="isEdit"
              label="name"
              :loading="isSearchStockItem"
              :placeholder="$t('Global.search_Placeholder')"
            )
              template(slot="no-options") {{ $t('Global.search_NoResults') }}

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('productItem')"
          ) {{ veeErrors.first('productItem') }}

          .info(v-if="form.productItem")
            div.flexbox.m-top-10.font-size-small
              p {{ $t('ProductionPlaning.stockOnHandQuantity') }}:
              p.m-left-10.font-weight-bold.c-dark {{ selectedStock.baseUnitQuantity }} {{ form.productItem.baseUnitName }}

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Quantity') }}
          .control
            customNumberInput(
              id="production-detail-form-quantity"
              name="quantity"
              :data-vv-as="$t('Dictionary.Quantity')"
              v-model="form.quantity"
              v-validate="{required: true, max: $getConst('MAX_DIGITS_FOR_QUANTITY'), decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than: 0}"
              :error="veeErrors.has('quantity')"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )
        
        .Form-item.required
          label.Form-item-label
            | {{ $t('Dictionary.Unit') }}
          .control.form-item-select
            CustomSelect(
              name="unitId"
              :optionData="filteredUnitList"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('Dictionary.Unit')"
              :isSearchActive="true"
              :error="veeErrors.has('unitId')"
              :isDefaultTextActive="false"
              :defaultTitle="$t('PriceQuota.popupForm_Field_Unit_Unselected')"
              optionTitle="name"
              selectedEmitValue="id"
              id="select-raw-material-planning-detail-unit"
              :isDisabled="isEdit || !form.unitId"
            )

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        v-if="!isEdit"
        id="checkbox-production-planning-detail-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Global.detail') })"
        v-model="saveAndNew")

      Button.uppercase(
        primary,
        size="medium"
        type="submit"
        variant="full"
        id="button-production-action-detail"
        form="Production-Detail-Form"
        :disabled="isRunAction"
        :isLoading="isRunAction"
      )
        span {{ formTitle }}

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import moment from 'moment'
import CustomSelect from '@/view/global/custom-select'

export default {
  name: 'RawMaterialPlanningDetailForm',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    CustomSelect
  },

  data () {
    return {
      saveAndNew: true,
      selectOptions: [],
      form: {
        productItem: {
          id: null,
          name: null,
          unitId: null
        },
        quantity: null,
        unitId: null
      },
      selectedStock: {}
    }
  },
  computed: {
    isEdit () {
      return this.status === 'edit'
    },
    filteredUnitList () {
      if (!this.form.productItem) return
      return this.getRelatedUnitList(this.form.productItem.baseUnitId || this.form.productItem.unitId)
    },
    formTitle () {
      if (this.isEdit) {
        return this.$t('Global.editData', { field: this.$t('Dictionary.ProductItem') })
      } else {
        return this.$t('Global.addData', { field: this.$t('Dictionary.ProductItem') })
      }
    },
    headerInfo () {
      return this.$route.params.header
    },

    isRunAction () {
      return this.$wait.is(['createRawMaterialPlanningDetail', 'onSubmitForm', 'fetchRawMaterialPlanningDetailList'])
    },

    isSearchStockItem () {
      return this.$wait.is('searchStockItem')
    },
    isSettingUpdateData () {
      return this.$wait.is('setEditData')
    },
    //
    ...mapGetters('Units', ['findUnit', 'getRelatedUnitList'])
  },

  methods: {

    ...mapActions('Stock', ['searchStockItem']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Transfer', ['getStockInfo']),
    ...mapActions('RawMaterialPlanning', [
      'createRawMaterialPlanningDetail',
      'updateRawMaterialPlanningDetail',
      'fetchRawMaterialPlanningDetail',
      'fetchRawMaterialPlanningDetailList'
    ]),

    ...mapMutations('RawMaterialPlanning', [
      'SHOW_DETAIL'
    ]),

    getOptions (search) {
      this.searchStockItem({ Text: search, onlyBaseUnit: true, types: [ 2 ] })
        .then(res => {
          if (res) {
            this.selectOptions = res.data.items
          }
        })
    },

    formFocus () {
      this.$refs.focusableInput.$refs.search.focus()
    },

    formReset () {
      this.form = {
        productItem: {
          id: null,
          name: null,
          unitId: null
        },
        quantity: null
      }
      this.selectOptions = []
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async onStockChange () {
      if (!this.form.productItem.id) return
      const requestData = {
        StoreId: this.headerInfo.storeId,
        StockItemId: this.form.productItem.id,
        UnitId: this.form.productItem.baseUnitId,
        ForDate: moment().format('YYYY-MM-DD')
      }
      this.form.unitId = this.form.productItem.baseUnitId
      const res = await this.getStockInfo(requestData)
      this.selectedStock = ({
        ...res.data
      })
    },

    async setEditData () {
      const res = await this.fetchRawMaterialPlanningDetail(this.$route.params.detailId)
      if (res) {
        const { rawMaterialPlanningDetail } = res.data
        const selectedUnit = this.findUnit(rawMaterialPlanningDetail.baseUnitId)
        this.form.quantity = rawMaterialPlanningDetail.quantity
        this.form.productItem = {
          id: rawMaterialPlanningDetail.stockItemId,
          name: rawMaterialPlanningDetail.stockItemName,
          baseUnitName: selectedUnit.name,
          baseUnitId: rawMaterialPlanningDetail.baseUnitId
        }
        this.form.unitId = rawMaterialPlanningDetail.unitId
      }
    },

    async addNewDetail () {
      const payload = {
        stockItemId: this.form.productItem.id,
        quantity: this.form.quantity,
        rawMaterialPlanningHeaderId: this.headerInfo.id,
        unitId: this.form.unitId
      }
      const res = await this.createRawMaterialPlanningDetail(payload)
      if (res) {
        const productionList = await this.fetchRawMaterialPlanningDetailList(this.headerInfo.id)
        if (productionList) {
          this.SHOW_DETAIL({
            RawMaterialPlaningHeader: this.headerInfo,
            RawMaterialPlanningDetailList: productionList.data.rawMaterialPlanningDetailList
          })
        }
        const message = this.$t('Global.successCreateMessage', { field: this.$t('Dictionary.ProductItem') })
        this.notifyShow({ message })
        if (this.saveAndNew) {
          this.formReset()
          this.$nextTick(() => {
            this.formFocus()
          })
        } else {
          this.close()
        }
      }
    },

    async editDetail () {
      const payload = {
        quantity: this.form.quantity,
        id: this.$route.params.detailId
      }
      const res = await this.updateRawMaterialPlanningDetail(payload)
      if (res) {
        const productionList = await this.fetchRawMaterialPlanningDetailList(this.headerInfo.id)
        if (productionList) {
          this.SHOW_DETAIL({
            RawMaterialPlaningHeader: this.headerInfo,
            RawMaterialPlanningDetailList: productionList.data.rawMaterialPlanningDetailList
          })
        }
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('Dictionary.ProductItem') })
        this.notifyShow({ message })
        this.close()
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      if (this.isEdit) await this.editDetail()
      else await this.addNewDetail()
    }, 'onSubmitForm')
  },

  async mounted () {
    if (this.isEdit) {
      this.$wait.start('isSettingUpdateData')
      await this.setEditData()
      this.$wait.end('isSettingUpdateData')
    } else {
      this.$nextTick(() => {
        this.formFocus()
      })
    }
  },

  watch: {
    'form.productItem' (val) {
      if (!val) return
      this.onStockChange()
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.select-dropdown-custom-selected) {
    width: 100% !important;
    min-height: 54px !important;
  }
</style>
