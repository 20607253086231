var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _c("div", [_vm._v(_vm._s(_vm.formTitle))]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "m-top-25" }, [
          _c("h4", { staticClass: "m-bottom-10" }, [
            _vm._v(_vm._s(_vm.$t("Dictionary.DocNo"))),
          ]),
          _vm.headerInfo
            ? _c("span", { staticClass: "ff-mono" }, [
                _vm._v(_vm._s(_vm.headerInfo.code)),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "Production-Detail-Form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _vm.headerInfo
              ? _c("div", { staticClass: "Form-item Form-item-info" }, [
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("Dictionary.Store")) + " :"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.headerInfo.storeName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.$t("Dictionary.Date")) + " :"),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(_vm.headerInfo.planningDate)
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.ProductItem"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "custom-search",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "focusableInput",
                      attrs: {
                        "component-name":
                          "production-form-detail-productionItem",
                        name: "productItem",
                        "data-vv-as": _vm.$t("Dictionary.ProductItem"),
                        inputClass: {
                          "is-danger": _vm.veeErrors.has("productItem"),
                        },
                        "on-search": _vm.getOptions,
                        options: _vm.selectOptions,
                        disabled: _vm.isEdit,
                        label: "name",
                        loading: _vm.isSearchStockItem,
                        placeholder: _vm.$t("Global.search_Placeholder"),
                      },
                      model: {
                        value: _vm.form.productItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productItem", $$v)
                        },
                        expression: "form.productItem",
                      },
                    },
                    [
                      _c("template", { slot: "no-options" }, [
                        _vm._v(_vm._s(_vm.$t("Global.search_NoResults"))),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("productItem"),
                      expression: "veeErrors.has('productItem')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("productItem")))]
              ),
              _vm.form.productItem
                ? _c("div", { staticClass: "info" }, [
                    _c(
                      "div",
                      { staticClass: "flexbox m-top-10 font-size-small" },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("ProductionPlaning.stockOnHandQuantity")
                            ) + ":"
                          ),
                        ]),
                        _c(
                          "p",
                          { staticClass: "m-left-10 font-weight-bold c-dark" },
                          [
                            _vm._v(
                              _vm._s(_vm.selectedStock.baseUnitQuantity) +
                                " " +
                                _vm._s(_vm.form.productItem.baseUnitName) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Quantity"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value:
                          "required|decimal:6|min_value:0|max_value:999999.999999",
                        expression:
                          "'required|decimal:6|min_value:0|max_value:999999.999999'",
                      },
                    ],
                    attrs: {
                      id: "production-detail-form-quantity",
                      name: "quantity",
                      "data-vv-as": _vm.$t("Dictionary.Quantity"),
                      error: _vm.veeErrors.has("quantity"),
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Unit"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    attrs: {
                      name: "unitId",
                      optionData: _vm.filteredUnitList,
                      "data-vv-as": _vm.$t("Dictionary.Unit"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("unitId"),
                      isDefaultTextActive: false,
                      defaultTitle: _vm.$t(
                        "PriceQuota.popupForm_Field_Unit_Unselected"
                      ),
                      optionTitle: "name",
                      selectedEmitValue: "id",
                      id: "select-production-detail-unit",
                      isDisabled: _vm.isEdit || !this.form.productItem.id,
                    },
                    model: {
                      value: _vm.form.unitId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "unitId", $$v)
                      },
                      expression: "form.unitId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("unitId"),
                        expression: "veeErrors.has('unitId')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("unitId") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-production-planning-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Global.detail"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "medium",
                type: "submit",
                variant: "full",
                id: "button-production-action-detail",
                form: "Production-Detail-Form",
                disabled: _vm.isRunAction,
                isLoading: _vm.isRunAction,
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.formTitle))])]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }