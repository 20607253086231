var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.editData", {
                      field: _vm.$t("ProductionPlaning.ProductionPlaning"),
                    })
                  )
                ),
              ]
            : [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.addData", {
                      field: _vm.$t("ProductionPlaning.ProductionPlaning"),
                    })
                  )
                ),
              ],
        ],
        2
      ),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isPageLoading
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "production-planing-header-form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ProductionPlaning.productionPlaningStore")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          ref: "firstInput",
                          attrs: {
                            "component-name":
                              "production-planing-header-planing-store",
                            name: "storeName",
                            "data-vv-as": _vm.$t(
                              "ProductionPlaning.productionPlaningStore"
                            ),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("storeName"),
                            },
                            "on-search": _vm.getOptionsStore,
                            options: _vm.selectMiniStoreList,
                            disabled: _vm.isEdit,
                            label: "name",
                            searchAll: true,
                            withDebounce: false,
                            loading: _vm.isSearchStoreLists,
                            placeholder: _vm.$t(
                              "ProductionPlaning.Popup_StoreName_Placeholder"
                            ),
                          },
                          on: {
                            input: _vm.inputSelection,
                            resetOptions: _vm.resetOptions,
                          },
                          model: {
                            value: _vm.form.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "storeId", $$v)
                            },
                            expression: "form.storeId",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("storeName"),
                              expression: "veeErrors.has('storeName')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("storeName"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("ProductionPlaning.ProductionPlaningName")
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.nameValidate,
                              expression: "nameValidate",
                            },
                          ],
                          attrs: {
                            id: "input-tax-form-name",
                            name: "name",
                            "data-vv-as": _vm.$t(
                              "ProductionPlaning.ProductionPlaningName"
                            ),
                            error: _vm.veeErrors.has("name"),
                            isDelayInput: true,
                            disabled:
                              _vm.isEdit || _vm.form.storeId.id === "-1",
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "action-control" },
                          [
                            _vm.isNameValidateControl
                              ? _c("Loading")
                              : _vm._e(),
                            !_vm.isNameValidateControl && _vm.isNameValid
                              ? _c("Icon", {
                                  staticClass: "c-success",
                                  attrs: { name: "icon-check" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("name"),
                              expression: "veeErrors.has('name')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("name") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Dictionary.Date"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("CustomDatepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          class: {
                            "is-danger": _vm.veeErrors.has(`planningDate`),
                          },
                          attrs: {
                            id: "production-planing-header-planning-date",
                            name: "planningDate",
                            "data-vv-as": _vm.$t("Dictionary.Date"),
                            pickerType: "manuel",
                            inputIconRight: "icon-datepicker-big",
                            inputIcon: "",
                            disabled: _vm.isEdit,
                            disabledStartDate: _vm.disableDate,
                          },
                          model: {
                            value: _vm.form.planningDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "planningDate", $$v)
                            },
                            expression: "form.planningDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("planningDate"),
                            expression: "veeErrors.has('planningDate')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("planningDate")))]
                    ),
                  ]),
                  !_vm.isEdit
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Form-item border-bottom-gray p-bottom-10",
                        },
                        [
                          _c("MultiRadioSelect", {
                            attrs: { data: _vm.selectType() },
                            model: {
                              value: _vm.type,
                              callback: function ($$v) {
                                _vm.type = $$v
                              },
                              expression: "type",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === 1
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "Form-item",
                            class: _vm.isOnlyListUsage ? "required" : "",
                          },
                          [
                            _c("MultiSearchSelect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: { required: _vm.isOnlyListUsage },
                                  expression: "{required: isOnlyListUsage}",
                                },
                              ],
                              attrs: {
                                searchAction:
                                  "ListsProduction/searchProductionListHeader",
                                searchResultKey: "productionListHeaderList",
                                formLabel: _vm.$t("Dictionary.List"),
                                name: "productionPlanningFormList",
                                error: _vm.veeErrors.has(
                                  "productionPlanningFormList"
                                ),
                                moduleName: "production-planning",
                                searchAll: true,
                              },
                              model: {
                                value: _vm.ProductionListHeaderList,
                                callback: function ($$v) {
                                  _vm.ProductionListHeaderList = $$v
                                },
                                expression: "ProductionListHeaderList",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm.type === 2
                    ? [
                        _c("div", { staticClass: "m-top-20" }, [
                          _c("div", { staticClass: "Form-item required" }, [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(_vm._s(_vm.$t("Dictionary.Date"))),
                            ]),
                            _c("div", { staticClass: "flexbox align-center" }, [
                              _c("div", { staticClass: "picker" }, [
                                _c(
                                  "div",
                                  { staticClass: "control form-item-select" },
                                  [
                                    _c("CustomDatepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      class: {
                                        "is-danger":
                                          _vm.veeErrors.has(`startDate`),
                                      },
                                      attrs: {
                                        id: "production-planing-header-planning-request-start-date",
                                        name: "startDate",
                                        "data-vv-as": _vm.$t("Dictionary.Date"),
                                        pickerType: "manuel",
                                        inputIconRight: "icon-datepicker-big",
                                        inputIcon: "",
                                        position: "top-left",
                                        disabledStartDate: _vm.dateStartDisable,
                                      },
                                      model: {
                                        value: _vm.form.dateStart,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dateStart", $$v)
                                        },
                                        expression: "form.dateStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.veeErrors.has("startDate"),
                                        expression:
                                          "veeErrors.has('startDate')",
                                      },
                                    ],
                                    staticClass: "Form-item-help is-danger",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.veeErrors.first("startDate"))
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "picker m-left-20" }, [
                                _c(
                                  "div",
                                  { staticClass: "control form-item-select" },
                                  [
                                    _c("CustomDatepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      class: {
                                        "is-danger":
                                          _vm.veeErrors.has(`endDate`),
                                      },
                                      attrs: {
                                        id: "production-planing-header-planning-request-end-date",
                                        name: "endDate",
                                        "data-vv-as": _vm.$t("Dictionary.Date"),
                                        pickerType: "manuel",
                                        inputIconRight: "icon-datepicker-big",
                                        inputIcon: "",
                                        position: "top",
                                        disabledStartDate: _vm.dateEndDisable,
                                      },
                                      model: {
                                        value: _vm.form.dateEnd,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dateEnd", $$v)
                                        },
                                        expression: "form.dateEnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.veeErrors.has("endDate"),
                                        expression: "veeErrors.has('endDate')",
                                      },
                                    ],
                                    staticClass: "Form-item-help is-danger",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.veeErrors.first("endDate"))
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "Form-item required" }, [
                            _c("label", { staticClass: "Form-item-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("ProductionPlaning.requestedStore")
                                )
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "control form-item-select" },
                              [
                                _c("Icon", {
                                  staticClass: "icon-down-arrow",
                                  attrs: { name: "icon-down-arrow" },
                                }),
                                _c("customSelectInput", {
                                  attrs: {
                                    name: "fromStoreName",
                                    optionData: _vm.requestedStoreSelectList,
                                    id: "production-planing-header-planning-requested-from-store",
                                    optionIdName:
                                      "production-planing-header-planning-requested-from-store-option",
                                  },
                                  model: {
                                    value: _vm.form.toStoreId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "toStoreId", $$v)
                                    },
                                    expression: "form.toStoreId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "items" },
                              [
                                _c("SelectedList", {
                                  attrs: {
                                    label: "name",
                                    moduleName: "production-planing",
                                  },
                                  model: {
                                    value: _vm.toStoreList,
                                    callback: function ($$v) {
                                      _vm.toStoreList = $$v
                                    },
                                    expression: "toStoreList",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "Form-item m-top-20" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("ProductionPlaning.formFieldDescription"))
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "textarea",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.description,
                              expression: "form.description",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:128",
                              expression: "'max:128'",
                            },
                          ],
                          ref: "descriptionInput",
                          staticClass: "txt requestFormItem",
                          class: {
                            "is-danger": _vm.veeErrors.has("description"),
                          },
                          attrs: {
                            id: "productionPlaning-header-form-description",
                            name: "description",
                            autocomplete: "off",
                            "data-vv-as": _vm.$t(
                              "ProductionPlaning.formFieldDescription"
                            ),
                            rows: "3",
                            type: "text",
                          },
                          domProps: { value: _vm.form.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            '<p class="Form-item-help is-danger" v-show="veeErrors.has(\'description\')">' +
                              _vm._s(_vm.veeErrors.first("description")) +
                              "</p>"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                2
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "production-planing-submit-button",
                form: "production-planing-header-form",
                disabled:
                  _vm.isCreating ||
                  (_vm.type === 2 && _vm.toStoreList.length === 0),
                isLoading: _vm.isCreating,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.editData", {
                              field: _vm.$t(
                                "ProductionPlaning.ProductionPlaning"
                              ),
                            })
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t(
                                "ProductionPlaning.ProductionPlaning"
                              ),
                            })
                          )
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }