var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _c("div", [_vm._v(_vm._s(_vm.formTitle))]),
      ]),
      _c("template", { slot: "detail" }, [
        _c("div", { staticClass: "m-top-25" }, [
          _c("h4", { staticClass: "m-bottom-10" }, [
            _vm._v(_vm._s(_vm.$t("Dictionary.DocNo"))),
          ]),
          _c("span", { staticClass: "ff-mono" }, [
            _vm._v(_vm._s(_vm.headerInfo.code)),
          ]),
        ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "Production-Detail-Form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.ProductItem"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "focusableInput",
                    attrs: {
                      "component-name": "form-production-detail-stock-item",
                      name: "productItem",
                      "data-vv-as": _vm.$t("Dictionary.ProductItem"),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("productItem"),
                      },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      disabled: _vm.isEdit,
                      label: "name",
                      loading: _vm.isSearchingStockItem,
                      placeholder: _vm.$t("Global.search_Placeholder"),
                      directSelectOption: _vm.directSelectOption,
                    },
                    on: { input: _vm.fetchStockItems },
                    model: {
                      value: _vm.form.productItem,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productItem", $$v)
                      },
                      expression: "form.productItem",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("productItem"),
                      expression: "veeErrors.has('productItem')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("productItem")))]
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Unit"))),
              ]),
              _c("div", { staticClass: "control with-icon" }, [
                _c(
                  "div",
                  { staticClass: "form-item-select" },
                  [
                    _c("Icon", {
                      staticClass: "icon-down-arrow",
                      attrs: { name: "icon-down-arrow" },
                    }),
                    _c("customSelectInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      attrs: {
                        name: "unitId",
                        disabled: _vm.isEdit,
                        optionData: _vm.filteredUnitList,
                        "data-vv-as": _vm.$t("Dictionary.Unit"),
                        error: _vm.veeErrors.has("unitId"),
                      },
                      on: { input: _vm.fetchStockItems },
                      model: {
                        value: _vm.form.productItem.unitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.productItem, "unitId", $$v)
                        },
                        expression: "form.productItem.unitId",
                      },
                    }),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("unitId"),
                          expression: "veeErrors.has('unitId')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("unitId") },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Quantity"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-number-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                          max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                        },
                        expression:
                          "{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                      },
                    ],
                    attrs: {
                      id: "production-detail-form-quantity",
                      name: "quantity",
                      "data-vv-as": _vm.$t("Dictionary.Quantity"),
                      error: _vm.veeErrors.has("quantity"),
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                  _vm.form.productItem
                    ? _c(
                        "div",
                        { staticClass: "flexbox m-top-10 font-size-small" },
                        [
                          _c(
                            "p",
                            { staticClass: "c-success font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Dictionary.recipe_cost_info"))
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "m-left-10 font-weight-normal c-dark",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.stockItemInfo.unitPrice
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "c-success font-weight-bold m-left-10",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Production.StockOnHandQuantity"))
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "m-left-10 font-weight-normal c-dark",
                            },
                            [_vm._v(_vm._s(_vm.stockItemInfo.baseUnitQuantity))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-inventory-detail-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Global.detail"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "medium",
                type: "submit",
                variant: "full",
                id: "button-production-action-detail",
                form: "Production-Detail-Form",
                disabled: _vm.isRunAction,
                isLoading: _vm.isRunAction,
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.formTitle))])]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }