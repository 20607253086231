import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import Productions from '../model'
import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import BatchList from '../model/production-batch'

export default {
  namespaced: true,
  state: {
    Productions: new Productions(),
    batchList: new BatchList(),
    Page: {
      ...Page
    },
    PageLinks: [
      'Production',
      'ProductionPlaning',
      'RawMaterialPlanning'
    ]
  },
  getters: {
    PageLinks: state => state.PageLinks,
    Productions: state => state.Productions.list,
    isEmptyProductionList: state => state.Productions.list.length === 0,
    Page: state => PageGetters({ state, stateName: 'Page' }),
    findProductionHeader: state => headerId => state.Productions.findItem(headerId),
    BatchList: state => state.batchList.list
  },
  actions: {
    async fetchProductionsList({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'fetchProductionsList',
        methodName: 'fetchProductionsList',
        method: () => {
          return HTTP.post('Production/header/all', params)
        },
        success: result => {
          commit('UPDATE_LIST', result.data)
          return result
        }
      })
      return results
    },
    async fetchProduct({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchProduct',
        methodName: 'fetchProduct',
        method: () => {
          return HTTP.get('Production/header', {
            params: { Id }
          })
        },
        success: result => {
          commit('CHANGE_HEADER_DATA', result.data.productionHeader)
          commit('SHOW_DETAIL', result.data)
          return result
        }
      })
      return results
    },
    async getProductionDocument({ commit }, Id) {
      const results = await requestHandler({
        title: 'getProductionDocument',
        methodName: 'getProductionDocument',
        method: () => {
          return HTTP.get('Production/header', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async fetchProductDetailInfo({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchProductDetailInfo',
        methodName: 'fetchProductDetailInfo',
        method: () => {
          return HTTP.get('Production/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async createProductionHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createProductionHeader',
        methodName: 'createProductionHeader',
        method: () => {
          return HTTP.post('Production/header', payload)
        },
        success: result => result
      })
      return results
    },
    async updateProductionHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateProductionHeader',
        methodName: 'updateProductionHeader',
        method: () => {
          return HTTP.put('Production/header', payload)
        },
        success: result => result
      })
      return results
    },
    async createProductionDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createProductionDetail',
        methodName: 'createProductionDetail',
        method: () => {
          return HTTP.post('Production/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async updateProductionDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateProductionDetail',
        methodName: 'updateProductionDetail',
        method: () => {
          return HTTP.put('Production/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteProduction({commit, dispatch}, Id) {
      let results = await requestHandler({
        title: 'deleteProduction',
        methodName: 'deleteProduction',
        method: () => {
          return HTTP.delete('Production/header', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },
    async deleteProductionDetail({ commit }, Id) {
      let results = await requestHandler({
        title: 'deleteProductionDetail',
        methodName: 'deleteProductionDetail',
        method: () => {
          return HTTP.delete('Production/detail', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },
    async makeFinalizeProduction({ commit }, Id) {
      const results = await requestHandler({
        title: 'makeFinalizeProduction',
        methodName: 'makeFinalizeProduction',
        method: () => {
          return HTTP.put('Production/finalize', { Id })
        },
        success: result => result
      })
      return results
    },
    async getExpireDateData({ commit }, payload) {
      const results = await requestHandler({
        title: 'getExpireDateData',
        methodName: 'getExpireDateData',
        method: () => {
          return HTTP.post('Production/detail/batch', payload)
        },
        success: result => {
          commit('UPDATE_BATCH_LIST', result.data)
          return result
        }
      })
      return results
    },

    async createBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createBatch',
        methodName: 'createBatch',
        method: () => {
          return HTTP.post('Batch/production', payload)
        },
        success: result => result
      })
      return results
    },

    async updateBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateBatch',
        methodName: 'updateBatch',
        method: () => {
          return HTTP.put('Batch', payload)
        },
        success: result => result
      })
      return results
    },

    async validateBatchQuantity({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'validateBatchQuantity',
        methodName: 'validateBatchQuantity',
        method: () => {
          return HTTP.post('Production/detail/batch/validate', payload)
        },
        success: result => result
      })
      return results
    },

    async getBatchDetails({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getBatchDetails',
        methodName: 'getBatchDetails',
        method: () => {
          return HTTP.post('Batch/production/all', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteBatch({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteBatch',
        methodName: 'deleteBatch',
        method: () => {
          return HTTP.delete('Batch', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    RESET(state) {
      state.Transfer = new Productions()
      state.Page = {
        ...Page
      }
    },

    UPDATE_LIST(state, data) {
      state.Productions.addItems(data.productionHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    //  DETAIL STATUS MUTATION
    HIDE_DETAIL(state, productionHeader) {
      productionHeader.showDetail = false
    },

    UPDATE_TRANSFER_ITEM(state, item) {
      state.Productions.updateItem(item)
    },

    HIDE_OTHERS_DETAILS(state, productionHeader) {
      const others = state.Productions.list.filter(production => production.id !== productionHeader.id)
      others.forEach(otherProductions => {
        otherProductions.showDetail = false
      })
    },

    SHOW_DETAIL(state, data) {
      let production = state.Productions.findItem(data.productionHeader.id)
      if (production) {
        production.addDetails(data.productionDetailList.map(item => {
          return {
            ...item,
            headerCode: data.productionHeader.code
          }
        }))
        production.showDetail = true
      }
    },

    CHANGE_HEADER_DATA(state, header) {
      state.Productions.updateItem(header)
    },
    UPDATE_BATCH_LIST(state, data) {
      state.batchList.addItems(data.productionDetailList)
    },

    SHOW_BATCH_DETAIL(state, data) {
      let batchList = state.batchList.findItem(data.id)
      batchList.showDetail = true
      batchList.addDetails(data.batch.batchList)
    },
    HIDE_BATCH_DETAIL(state, batchList) {
      batchList.showDetail = false
    },
    SET_EXPIRATION_DATE_CONTROL(state, data) {
      data.production.expirationDateControlIsEnabled = data.expirationDateControl
    }
  }
}
