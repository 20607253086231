export function getProductionPlanningHeaderFields($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.DocNo')
      },
      get title() {
        return $t('Dictionary.DocNo')
      }
    },
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('ProductionPlaning.ProductionPlaningName')
      },
      get title() {
        return $t('ProductionPlaning.ProductionPlaningName')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Store')
      },
      get title() {
        return $t('Dictionary.Store')
      }
    },
    {
      name: 'planningDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Date')
      },
      get title() {
        return $t('Dictionary.Date')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}

export function getProductionPlanningDetailFields($t) {
  return [
    {
      name: 'productionStockItemName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.ProductItem')
      },
      get title() {
        return $t('Dictionary.ProductItem')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Unit')
      },
      get title() {
        return $t('Dictionary.Unit')
      }
    },
    {
      name: 'requestedQuantity',
      type: 'quantity',
      // talep miktarı
      is_visible: true,
      get short_title() {
        return $t('ProductionPlaning.requestedQuantity')
      },
      get title() {
        return $t('ProductionPlaning.requestedQuantity')
      }
    },
    {
      name: 'orderedQuantity',
      type: 'quantity',
      // sipariş
      is_visible: true,
      get short_title() {
        return $t('ProductionPlaning.orderedQuantity')
      },
      get title() {
        return $t('ProductionPlaning.orderedQuantity')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      // eldeki stok
      is_visible: true,
      get short_title() {
        return $t('ProductionPlaning.stockOnHandQuantity')
      },
      get title() {
        return $t('ProductionPlaning.stockOnHandQuantity')
      }
    },
    {
      name: 'recommendedQuantity',
      type: 'quantity',
      // önerilen miktar
      is_visible: true,
      get short_title() {
        return $t('ProductionPlaning.recommendedQuantity')
      },
      get title() {
        return $t('ProductionPlaning.recommendedQuantity')
      }
    },
    {
      name: 'plannedQuantity',
      type: 'quantity',
      editable: true,
      validationRules: 'required|decimal:6|min_value:0|max_value:999999.999',
      is_visible: true,
      get short_title() {
        return $t('ProductionPlaning.plannedQuantity')
      },
      get title() {
        return $t('ProductionPlaning.plannedQuantity')
      }
      // planlanan miktar
    },
    {
      name: 'baseUnitName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.BaseUnit')
      },
      get title() {
        return $t('Dictionary.BaseUnit')
      }
    }
  ]
}
