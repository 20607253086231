<template lang="pug">
 Popup
    template(slot="title")
     template(v-if="isEdit") {{ $t('Global.editData', { field: $t('ProductionPlaning.ProductionPlaning') }) }}
     template(v-else) {{ $t('Global.addData', { field: $t('ProductionPlaning.ProductionPlaning') }) }}

    template(slot="content")
      Loading(v-if="isPageLoading")
      form.Form(
        v-else,
        @submit.prevent="onSubmitForm",
        id="production-planing-header-form")

        .Form-item.required
          label.Form-item-label
            | {{ $t('ProductionPlaning.productionPlaningStore') }}
          .control.form-item-select
            custom-search(
              ref="firstInput"
              component-name="production-planing-header-planing-store"
              name="storeName",
              v-model="form.storeId"
              :data-vv-as="$t('ProductionPlaning.productionPlaningStore')",
              v-validate="'required|is_not:-1'",
              :inputClass="{ 'is-danger': veeErrors.has('storeName') }",
              :on-search="getOptionsStore",
              :options="selectMiniStoreList",
              :disabled="isEdit"
              label="name",
              :searchAll="true"
              :withDebounce="false"
              :loading="isSearchStoreLists"
              :placeholder="$t('ProductionPlaning.Popup_StoreName_Placeholder')"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="veeErrors.has('storeName')"
              :errorName="veeErrors.first('storeName')"
            )


        .Form-item.required
          label.Form-item-label
            | {{ $t('ProductionPlaning.ProductionPlaningName') }}
          .control
            customTextInput(
              id="input-tax-form-name"
              v-model="form.name"
              v-validate="nameValidate"
              name="name"
              :data-vv-as="$t('ProductionPlaning.ProductionPlaningName')"
              :error="veeErrors.has('name')"
              :isDelayInput="true"
              :disabled="isEdit || form.storeId.id === '-1'"
            )

            .action-control
              Loading(v-if="isNameValidateControl")
              Icon.c-success(v-if="!isNameValidateControl && isNameValid", name="icon-check")

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Date') }}
          .control.form-item-select
            CustomDatepicker(
              id="production-planing-header-planning-date",
              name="planningDate",
              :data-vv-as="$t('Dictionary.Date') ",
              v-model="form.planningDate",
              :class="{ 'is-danger': veeErrors.has(`planningDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabled="isEdit"
              :disabledStartDate="disableDate")
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('planningDate')"
          ) {{ veeErrors.first('planningDate') }}

        .Form-item.border-bottom-gray.p-bottom-10(
          v-if="!isEdit"
        )
          MultiRadioSelect(
            v-model="type"
            :data="selectType()"
          )

        template(v-if="type === 1")

          .Form-item(:class="isOnlyListUsage ? 'required' : '' ")
            MultiSearchSelect(
              v-model="ProductionListHeaderList",
              searchAction="ListsProduction/searchProductionListHeader",
              searchResultKey="productionListHeaderList",
              :formLabel="$t('Dictionary.List')",
              name="productionPlanningFormList"
              v-validate="{required: isOnlyListUsage}"
              :error="veeErrors.has('productionPlanningFormList')"
              moduleName="production-planning",
              :searchAll="true"
            )

        template(v-else-if="type === 2")
          .m-top-20
            .Form-item.required
              label.Form-item-label {{ $t('Dictionary.Date') }}
              .flexbox.align-center
                .picker
                  .control.form-item-select
                    CustomDatepicker(
                      id="production-planing-header-planning-request-start-date"
                      name="startDate",
                      :data-vv-as="$t('Dictionary.Date') ",
                      v-model="form.dateStart",
                      :class="{ 'is-danger': veeErrors.has(`startDate`) }"
                      v-validate="'required'"
                      pickerType="manuel",
                      inputIconRight="icon-datepicker-big"
                      inputIcon="",
                      position="top-left",
                      :disabledStartDate="dateStartDisable")
                  p.Form-item-help.is-danger(
                  v-show="veeErrors.has('startDate')"
                  ) {{ veeErrors.first('startDate') }}

                .picker.m-left-20
                  .control.form-item-select
                    CustomDatepicker(
                      id="production-planing-header-planning-request-end-date"
                      name="endDate",
                      :data-vv-as="$t('Dictionary.Date') ",
                      v-model="form.dateEnd",
                      :class="{ 'is-danger': veeErrors.has(`endDate`) }"
                      v-validate="'required'"
                      pickerType="manuel",
                      inputIconRight="icon-datepicker-big"
                      inputIcon="",
                      position="top",
                      :disabledStartDate="dateEndDisable")

                  p.Form-item-help.is-danger(
                  v-show="veeErrors.has('endDate')"
                  ) {{ veeErrors.first('endDate') }}

            .Form-item.required
              label.Form-item-label
                | {{ $t('ProductionPlaning.requestedStore') }}
              .control.form-item-select
                Icon.icon-down-arrow(name="icon-down-arrow")
                customSelectInput(
                  name="fromStoreName"
                  :optionData="requestedStoreSelectList"
                  v-model="form.toStoreId"
                  id="production-planing-header-planning-requested-from-store"
                  optionIdName="production-planing-header-planning-requested-from-store-option"
                )

              .items
                SelectedList(
                  label="name",
                  moduleName="production-planing",
                  v-model="toStoreList"
                )

        .Form-item.m-top-20
          label.Form-item-label {{ $t('ProductionPlaning.formFieldDescription') }}
          .control
            textarea.txt.requestFormItem#productionPlaning-header-form-description(
              name="description"
              autocomplete="off"
              :data-vv-as="$t('ProductionPlaning.formFieldDescription')"
              v-model="form.description"
              :class="{ 'is-danger': veeErrors.has('description')}",
              rows="3"
              ref="descriptionInput"
              v-validate="'max:128'",
              type="text")

              p.Form-item-help.is-danger(
                v-show="veeErrors.has('description')"
              ) {{ veeErrors.first('description') }}

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        id="production-planing-submit-button",
        form="production-planing-header-form",
        :disabled="isCreating || (type === 2 && toStoreList.length === 0)",
        :isLoading="isCreating"
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('ProductionPlaning.ProductionPlaning') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('ProductionPlaning.ProductionPlaning') }) }}
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import moment from 'moment'
//
import MultiRadioSelect from '@/view/global/multiselect-radio'
import SelectedList from '@/view/global/selected-list'
import MultiSearchSelect from '@/view/global/multi-search-select'

export default {
  name: 'ProductionPlaningHeaderForm',

  /**
  *
  */

  components: {
    MultiRadioSelect,
    SelectedList,
    MultiSearchSelect
  },

  /**
  *
  */

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      type: 0,
      form: {
        name: '',
        storeId: {
          id: '-1',
          name: ''
        },
        planningDate: new Date(),
        dateStart: moment(1, 'DD').hour(0).minute(0)._d,
        dateEnd: moment().hour(0).minute(0)._d,
        toStoreId: '-1',
        description: null
      },
      requestedStoreList: [],
      ProductionListHeaderList: [],
      selectMiniStoreList: [],
      toStoreList: [],
      disableDate: {
        to: moment()._d
      },
      isOnlyListUsage: false
    }
  },

  /**
  *
  **/

  computed: {
    //
    ...mapGetters('Stores', ['storeList']),
    ...mapGetters('Settings', ['UserInfo', 'permissions']),

    //
    storeListItems () {
      return this.isEdit ? this.storeList : this.storeList.filter(i => i.canProduction)
    },

    isPageLoading () {
      return this.$wait.is('pageLoading')
    },

    isNameValidateControl () {
      return this.veeFields.name ? this.veeFields.name.pending : null
    },

    isNameValid () {
      return this.veeFields.name ? this.veeFields.name.valid : null
    },

    nameValidate () {
      const url = `/productionPlanning/header/check/name?StoreId=${this.form.storeId.id}&PlanningDate=${moment(this.form.planningDate).format('YYYY-MM-DD')},Name`
      return `required|max:32|very_singularity:${url}`
    },

    dateStartDisable () {
      return {
        from: moment(this.form.dateEnd)._d
      }
    },
    dateEndDisable () {
      return {
        to: moment(this.form.dateStart)._d
      }
    },

    isCreating () {
      return this.$wait.is(['createProductionPlanningHeader', 'updateProductionPlanningHeader', 'onSubmitForm'])
    },

    isEdit () {
      return this.status === 'edit'
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },

    requestedStoreSelectList () {
      let selectList = []
      this.requestedStoreList.map(i => {
        if (!this.toStoreList.some(el => el.id === i.id)) selectList.push(i)
      })
      return selectList
    }
  },

  /**
  *
  **/

  methods: {

    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Stores', ['getStoreList','fetchStoreByName']),
    ...mapActions('ProductionPlaning', [
      'fetchRequestedItemsForList',
      'createProductionPlanningHeader',
      'fetchProductPlanningDetail',
      'updateProductionPlanningHeader',
      'fetchProductPlanningHeader'
    ]),
    ...mapMutations('ProductionPlaning', [
      'SHOW_DETAIL'
    ]),

    setForm (item) {
      this.form.storeId = { id: item.storeId, name: item.storeName }
      this.form.planningDate = item.planningDate
      this.form.description = item.description
      this.form.name = item.name
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    selectType () {
      if (this.isOnlyListUsage) this.type = 1
      return [
        {
          text: this.$t('Global.manuel'),
          value: 0,
          disabled: this.isOnlyListUsage
        },
        {
          text: this.$t('Dictionary.List'),
          value: 1
        },
        {
          text: this.$t('ProductionPlaning.showRequest'),
          value: 2,
          disabled: this.form.storeId.id === '-1' || this.isOnlyListUsage
        }
      ]
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            const canProductionList = res.data.items.filter(obj1 => 
              this.storeListItems.some(obj2 => obj2.id === obj1.id)
            );
            this.selectMiniStoreList = canProductionList
          }
        })
    },
    inputSelection (data) {
      this.form.storeId.id = data.id
      this.form.storeId.name = data.name
    },
    resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    async getRequestedStoreList () {
      if (this.type !== 2) return
      const payload = {
        fromStoreId: this.form.storeId.id,
        StartDate: moment(this.form.dateStart).format('YYYY-MM-DD'),
        EndDate: moment(this.form.dateEnd).format('YYYY-MM-DD')
      }
      const res = await this.fetchRequestedItemsForList(payload)
      if (res) {
        this.requestedStoreList = res.data.storeList
        this.form.toStoreId = '-1'
        this.toStoreList = []
      }
    },

    async newProductionPlaning () {
      const payload = {
        storeId: this.form.storeId.id,
        planningDate: moment(this.form.planningDate).format('YYYY-MM-DD'),
        name: this.form.name,
        description: this.form.description
      }
      if (this.type === 1) {
        payload.productionListHeaderIds = this.ProductionListHeaderList.map(_ => _.id)
      } else if (this.type === 2) {
        payload.insertWithRequests = true
        payload.startDate = moment(this.form.dateStart).format('YYYY-MM-DD')
        payload.endDate = moment(this.form.dateEnd).format('YYYY-MM-DD')
        payload.toStoreIds = this.toStoreList.map(_ => _.id)
      }

      const res = await this.createProductionPlanningHeader(payload)

      if (res) {
        this.$emit('showDrafts')
        this.$emit('getProductionPlaningList')
        const message = this.$t('Global.successCreateMessage', { field: this.$t('ProductionPlaning.ProductionPlaning') })
        this.notifyShow({ message })
        this.closePopup()
        const list = await this.fetchProductPlanningDetail(res.data.id)
        // this.HIDE_OTHERS_DETAILS(res.data)
        const header = res.data
        this.SHOW_DETAIL({
          productionPlaningHeader: header,
          productionPlanningDetailList: list.data.productionPlanningDetailList
        })
        if (this.type === 0) {
          this.$router.push({
            params: {
              header,
              headerId: header.id
            },
            name: 'NewProductionPlaningDetail'
          })
        }
      }
    },

    async editProductionPlanning () {
      const payload = {
        id: this.$route.params.id,
        description: this.form.description
      }
      await this.updateProductionPlanningHeader(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('Global.successUpdateMessage', { field: this.$t('ProductionPlaning.ProductionPlaning') })
          this.notifyShow({ message })
          this.$emit('getProductionPlaningList')
          this.closePopup()
        })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) await this.editProductionPlanning()
        else await this.newProductionPlaning()
      })
    }, 'onSubmitForm')

  },

  /**
  *
  **/

  watch: {
    'form.toStoreId' (val) {
      if (val === '-1' || this.toStoreList.find(item => item === val)) return
      const item = this.requestedStoreList.find(_ => _.id === val)
      this.toStoreList.push(item)
      this.form.toStoreId = '-1'
    },

    toStoreList (val) {
      if (val.length === 0) {
        this.form.toStoreId = '-1'
      }
    },

    async 'form.storeId' (val) {
      await this.getRequestedStoreList()
    },

    async type () {
      await this.getRequestedStoreList()
    },

    async 'form.dateStart' () {
      await this.getRequestedStoreList()
    },

    async 'form.dateEnd' () {
      await this.getRequestedStoreList()
    }
  },

  /**
  *
  **/

  async beforeMount () {
    this.$wait.start('pageLoading')
    await this.getStoreList({pageNumber: 1, pageSize: 9999})
    if (this.isEdit) {
      if (this.$route.params.item) this.setForm(this.$route.params.item)
      else {
        this.fetchProductPlanningHeader(this.$route.params.id)
          .then(res => {
            if (!res) return
            this.setForm(res.data.productionPlanningHeader)
          })
      }
    } else {
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
      const isProductionStoreExist = this.storeListItems.find(i => i.id === this.UserInfo.defaultStoreId)
      if (isProductionStoreExist) this.form.storeId = { id: this.UserInfo.defaultStoreId, name: this.UserInfo.defaultStoreName }
    }
    this.$wait.end('pageLoading')
  }
}
</script>

<style lang="scss" scoped>

 .picker {
   flex: 1;
 }
</style>
