<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :isGetDataLoading="isFetchingData",
  :tableName="$t('Dictionary.rawMaterialPlanningTitle')",
  loading="deleteRawMaterialPlanningDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RemoveRawMaterialDetail',

  data () {
    return {
      fields: [
        {
          name: 'stockItemName',
          is_visible: true,
          short_title: this.$t('Dictionary.ProductItem'),
          title: this.$t('Dictionary.ProductItem')
        },
        {
          name: 'quantity',
          is_visible: true,
          short_title: this.$t('Dictionary.Quantity'),
          title: this.$t('Dictionary.Quantity')
        }
      ],
      item: {}
    }
  },

  async mounted () {
    this.item = this.$route.params.detailData
  },

  computed: {
    isFetchingData () {
      return this.$wait.is('fetchRawMaterialPlanningDetail')
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('RawMaterialPlanning', [
      'deleteRawMaterialDetail',
      'fetchRawMaterialDetailList'
    ]),

    ...mapMutations('RawMaterialPlanning', [
      'SHOW_DETAIL_RAW_LIST'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const payload = {
        RawMaterialPlanningHeaderId: this.$route.params.headerId,
        StockItemId: this.item.stockItemId
      }
      let result = await this.deleteRawMaterialDetail(payload)
      if (result) {
        const message = this.$t('Global.successRemoveMessage', { field: this.$t('Dictionary.ProductItem') })
        const res = await this.fetchRawMaterialDetailList(this.$route.params.headerId)
        if (res) {
          const detail = {
            RawMaterialPlanningDetailList: [
              ...res.data.rawMaterialDetailList
            ],
            RawMaterialPlaningHeader: {
              id: this.$route.params.headerId
            }
          }
          this.SHOW_DETAIL_RAW_LIST(detail)
        }
        this.notifyShow({ message })
        this.close()
      }
    }, 'itemRemove')
  }
}
</script>

<style lang="scss">
</style>
