<template lang="pug">
transition(name="zoom")
    .Alert(v-if="isPopupVisible")
      .Table-body
        .Table
          h4.Table-title.m-bottom-15 {{ $t('Dictionary.rawMaterialPlanningTitle') }}
          span {{description}}
          TableView.m-top-15(
            :componentName="'info-alert'"
            :data="data"
            :fields="fields"
            :actions="['export']"
            :readOnly="isProductItemWarning || isStockItemWarning"
            @delegateOnItemExport="showExportPopup"
          )
          portal(to="requestType")
            div(slot-scope="data")
              span {{checkRequestType(data)}}

          portal(to="code")
            div(slot-scope="header")
              Button.bgc-none(
              @click="goRequestedLists(header)"
              )
                span {{header.code}}

          .Table-footer.m-top-15
            Button(
            primary
            size="medium"
            id="btn-info-alert-cancel"
            type="button",
            ref="focusedButton"
            @click="closePopup"
            ) {{ closePopupText }}

            Button.m-left-10(
            danger
            size="medium"
            id="btn-info-alert-cancel"
            type="button",
            v-if="isProductItemWarning || isStockItemWarning"
            ref="focusedButton"
            @click="deletePopupItems"
            ) {{ $t('Dictionary.Delete') }}

      exportPopup(
        v-if="isExportPopupVisible"
        :templateId="templateId"
        :templateType="templateType"
        ref="exportPopupTemplate"
      )

</template>

<script>
import exportPopup from '@/view/global/export-popup'
import settingsExportPopup from '@/mixins/settingsExportPopup'

export default {
  name: 'InfoAlert',

  components: {
    exportPopup
  },

  mixins: [settingsExportPopup],

  data () {
    return {
      isPopupVisible: true,

      fields: [
        {
          name: 'code',
          type: 'portal',
          is_visible: !this.isProductItemWarning && !this.isStockItemWarning,
          short_title: this.$t('Dictionary.RequestNo'),
          title: this.$t('Dictionary.RequestNo')
        },
        {
          name: 'requestType',
          is_visible: !this.isProductItemWarning && !this.isStockItemWarning,
          type: 'portal',
          short_title: this.$t('OnHand.tableColumn_stockItemType'),
          title: this.$t('OnHand.tableColumn_stockItemType')
        },
        {
          name: 'stockItemName',
          is_visible: this.isProductItemWarning,
          short_title: this.$t('Dictionary.ProductItem'),
          title: this.$t('Dictionary.ProductItem')
        },
        {
          name: 'stockItemName',
          is_visible: this.isStockItemWarning,
          short_title: this.$t('Dictionary.StockItem'),
          title: this.$t('Dictionary.StockItem')
        }
      ],
      data: [],
      isExportPopupVisible: false
    }
  },

  props: {
    title: {
      type: String,
      default: null
    },

    isStockItemWarning: {
      type: Boolean,
      default: false
    },

    popupData: {
      type: Array,
      default: () => []
    },

    description: {
      type: String,
      default: ''
    },

    isTableActive: {
      type: Boolean,
      default: false
    },

    isProductItemWarning: {
      type: Boolean,
      default: false
    },

    closePopupText: {
      type: String,
      default () {
        return this.$t('Global.alert_OkButton')
      }
    }
  },

  mounted () {
    this.data = this.popupData || []
    if (!this.isTableActive) this.$refs.focusedButton.focus()
  },

  methods: {
    closePopup () {
      this.isPopupVisible = false
      this.$emit('closePopupAction')
    },

    deletePopupItems () {
      this.isPopupVisible = false
      this.$emit('deleteAction')
    },

    goRequestedLists (item) {
      this.$router.push({
        name: 'Requests',
        params: {
          headerCode: item.code,
          id: item.id
        }
      })
    },
    checkRequestType (item) {
      switch (item.requestType) {
        case 2:
          return this.$t('Dictionary.Order')
        case 3:
          return this.$t('Dictionary.Transfer')
        default:
          break
      }
    },

    showExportPopup (data) {
      const tempType = data.requestType === 3 ? 10 : 11
      this.exportFrame(data.id, tempType)
    }
  }
}
</script>

<style lang="scss" scoped>

.zoom-enter-active,
.zoom-leave-active {
  transition: 0.3s;
  opacity: 0;
  transform: scale(1.4);
}

.zoom-enter-to {
  opacity: 1;
  transform: scale(1);
}

.zoom-leave-to {
  transform: scale(0.6);
}

.Alert {
  z-index: $z-index-xl;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Table-body {
  overflow-y: auto;
  padding: 10px;
}

:deep(.Tableee-main)  {
  min-height: 175px;
}

.Table{
  &-title{
    color: $color-primary
  }
  &-footer{
    display: flex;
    justify-content: flex-end;
    Button{
      width: 60%;
    }
  }
}
</style>
