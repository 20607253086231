var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.editData", {
                      field: _vm.$t("Dictionary.Production"),
                    })
                  )
                ),
              ]
            : [
                _vm._v(
                  _vm._s(
                    _vm.$t("Global.addData", {
                      field: _vm.$t("Dictionary.Production"),
                    })
                  )
                ),
              ],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "production-header-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Store"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    ref: "firstInput",
                    attrs: {
                      "component-name": "production-header-store-name",
                      name: "storeName",
                      "data-vv-as": _vm.$t("Dictionary.Store"),
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("storeName"),
                      },
                      "on-search": _vm.getOptionsStore,
                      options: _vm.selectMiniStoreList,
                      disabled: _vm.isEdit || _vm.isGettingStores,
                      label: "name",
                      searchAll: true,
                      withDebounce: false,
                      loading: _vm.isSearchStoreLists,
                      placeholder: _vm.$t(
                        "Production.Header_Popup_StoreName_Placeholder"
                      ),
                    },
                    on: {
                      input: _vm.inputSelection,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.form.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "storeId", $$v)
                      },
                      expression: "form.storeId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("storeName"),
                        expression: "veeErrors.has('storeName')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("storeName") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Date"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: { "is-danger": _vm.veeErrors.has(`productionDate`) },
                    attrs: {
                      id: "production-header-form-datepicker",
                      name: "productionDate",
                      "data-vv-as": _vm.$t("Dictionary.Date"),
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                      disabled: _vm.isEdit,
                      disabledStartDate: _vm.disableDate,
                    },
                    model: {
                      value: _vm.form.productionDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productionDate", $$v)
                      },
                      expression: "form.productionDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("productionDate"),
                      expression: "veeErrors.has('productionDate')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("productionDate")))]
              ),
            ]),
            !_vm.isEdit
              ? _c(
                  "div",
                  {
                    staticClass: "Form-item",
                    class: _vm.isOnlyListUsage ? "required" : "",
                  },
                  [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Dictionary.List"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c(
                          "custom-search",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.isOnlyListUsage },
                                expression: "{required: isOnlyListUsage}",
                              },
                            ],
                            ref: "listInput",
                            attrs: {
                              "component-name": "production-header-form-list",
                              name: "production-header-form-list",
                              "data-vv-as": _vm.$t("Dictionary.List"),
                              "on-search": _vm.getOptionsList,
                              options: _vm.selectOptionsList,
                              error: _vm.veeErrors.has(
                                "production-header-form-list"
                              ),
                              label: "name",
                              loading: _vm.isSearchProductionListHeader,
                              searchAll: true,
                              placeholder: _vm.$t("Global.search_Placeholder"),
                            },
                            model: {
                              value: _vm.form.list,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "list", $$v)
                              },
                              expression: "form.list",
                            },
                          },
                          [
                            _c("template", { slot: "no-options" }, [
                              _vm._v(_vm._s(_vm.$t("Global.search_NoResults"))),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has(
                            "production-header-form-list"
                          ),
                          expression:
                            "veeErrors.has('production-header-form-list')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first(
                          "production-header-form-list"
                        ),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "selected-items" },
                      _vm._l(_vm.lists, function (item, index) {
                        return _c(
                          "Button",
                          {
                            key: index,
                            attrs: {
                              seashell: "",
                              id: `selected-product-item-${index}`,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeListItem(item)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _c("Icon", {
                              staticClass: "m-left-5",
                              attrs: {
                                name: "icon-popup-close",
                                id: `selected-stock-item-delete-${index}`,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isEdit
              ? _c(
                  "div",
                  { staticClass: "Form-item" },
                  [
                    _c("MultiSearchSelect", {
                      attrs: {
                        searchAction: "ProductionPlaning/searchProductPlanning",
                        searchResultKey: "productionPlanningHeaderList",
                        formLabel: _vm.$t(
                          "ProductionPlaning.ProductionPlaning"
                        ),
                        moduleName: "production",
                        isSearchListResetInEveryAction: true,
                        searchLabel: "nameWithDate",
                        hasSeachPayload: true,
                        searchPayload: _vm.productionPlanninglistSearchPayload,
                      },
                      model: {
                        value: _vm.planningList,
                        callback: function ($$v) {
                          _vm.planningList = $$v
                        },
                        expression: "planningList",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Production.formFieldDescription"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextareaInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:128",
                        expression: "'max:128'",
                      },
                    ],
                    ref: "descriptionInput",
                    attrs: {
                      name: "description",
                      "data-vv-as": _vm.$t("Production.formFieldDescription"),
                      error: _vm.veeErrors.has("description"),
                      rows: "3",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("description"),
                        expression: "veeErrors.has('description')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("description") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "button-production-add-header",
                form: "production-header-form",
                disabled: _vm.isCreating,
                isLoading: _vm.isCreating,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.editData", {
                              field: _vm.$t("Dictionary.Production"),
                            })
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t("Dictionary.Production"),
                            })
                          )
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }