<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :isGetDataLoading="isFetchingData",
  :tableName="$t('ProductionPlaning.removeDetailFormTitle')",
  loading="deleteProductionPlanningDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RemoveProductionDetail',

  data () {
    return {
      fields: [{
        name: 'productionStockItemName',
        is_visible: true,
        short_title: this.$t('Dictionary.ProductItem'),
        title: this.$t('Dictionary.ProductItem')
      },
      {
        name: 'plannedQuantity',
        is_visible: true,
        short_title: this.$t('Dictionary.Quantity'),
        title: this.$t('Dictionary.Quantity')
      },
      {
        name: 'baseUnitName',
        is_visible: true,
        short_title: this.$t('Dictionary.Unit'),
        title: this.$t('Dictionary.Unit')
      }],
      item: {}
    }
  },

  async mounted () {
    const res = await this.fetchProductPlanningDetailInfo(this.$route.params.detailId)
    if (res) {
      this.item = res.data.productionPlanningDetail
    }
  },

  computed: {
    ...mapGetters('Units', ['findUnit']),
    isFetchingData () {
      return this.$wait.is('fetchProductPlanningDetailInfo')
    }
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('ProductionPlaning', [
      'deleteProductionPlanningDetail',
      'fetchProductPlanningDetail',
      'fetchProductPlanningDetailInfo'
    ]),

    ...mapMutations('ProductionPlaning', [
      'SHOW_DETAIL'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.deleteProductionPlanningDetail(this.$route.params.detailId)
      if (result) {
        const message = this.$t('Global.successRemoveMessage', { field: this.$t('Dictionary.ProductItem') })
        const res = await this.fetchProductPlanningDetail(this.$route.params.id)
        if (res) {
          const detail = res.data.productionPlanningDetailList
          this.SHOW_DETAIL({
            productionPlaningHeader: {
              id: this.$route.params.id
            },
            productionPlanningDetailList: detail
          })
        }
        this.notifyShow({ message })
        this.close()
      }
    }, 'itemRemove')
  }
}
</script>

<style lang="scss">
</style>
