import BaseClass from '@/utils/baseclass'

export default class findItemProductionPlannings extends BaseClass {
  addItem(item) {
    let newItem = new ProductionPlanning(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.name = item.name
      findItem.isActive = item.isActive
      findItem.id = item.id
      findItem.code = item.code
      findItem.storeId = item.storeId
      findItem.storeName = item.storeName
      findItem.status = item.status
      findItem.planningDate = item.productionDate
      findItem.showDetail = false
      findItem.disabledRow = !item.isActive
    }
  }
}

export class ProductionPlanning {
  constructor(item) {
    this.id = item.id
    this.code = item.code
    this.name = item.name
    this.isActive = item.isActive
    this.storeId = item.storeId
    this.storeName = item.storeName
    this.status = item.status
    this.planningDate = item.planningDate
    this.description = item.description
    this.detailList = []
    this.showDetail = false
    this.disabledRow = !item.isActive
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new ProductionPlanningDetail(detail))
    })
  }

  deleteItem(Id) {
    let findIndex = this.findIndex(Id)
    if (findIndex > -1) {
      this.detailList.splice(findIndex, 1)
    }
  }

  findIndex(Id) {
    return this.detailList.findIndex(item => item.id === Id)
  }

  findItem(Id) {
    return this.detailList.find(item => item.id === Id)
  }

  get detailIsEmpty() {
    return this.detailList.length === 0
  }
}

export class ProductionPlanningDetail {
  constructor(detail) {
    this.id = detail.id
    this.productionPlanningHeaderId = detail.productionPlanningHeaderId
    this.storeId = detail.storeId
    this.headerCode = detail.headerCode
    this.productionStockItemId = detail.productionStockItemId
    this.productionStockItemName = detail.productionStockItemName
    this.quantity = detail.quantity
    this.requestedQuantity = detail.requestedQuantity
    this.stockOnHandQuantity = detail.stockOnHandQuantity
    this.recommendedQuantity = detail.recommendedQuantity
    this.plannedQuantity = detail.plannedQuantity
    this.orderedQuantity = detail.orderedQuantity
    this.baseUnitId = detail.baseUnitId
    this.baseUnitName = detail.baseUnitName
    this.storeName = detail.storeName
    this.planningDate = detail.planningDate
    this.planningName = detail.planningName
    this.unitId = detail.unitId
    this.unitName = detail.unitName
  }
}
