export function getRawMaterialPlanningHeaderFields($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.DocNo')
      },
      get title() {
        return $t('Dictionary.DocNo')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Store')
      },
      get title() {
        return $t('Dictionary.Store')
      }
    },
    {
      name: 'planningDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Date')
      },
      get title() {
        return $t('Dictionary.Date')
      }
    },
    {
      name: 'description',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ''
      },
      get title() {
        return ''
      }
    }
  ]
}

export function getRawMaterialPlanningDetailFields($t) {
  return [
    {
      name: 'stockItemName',
      is_visible: (item) => {
        return !item.isRawMaterial
      },
      get short_title() {
        return $t('Dictionary.ProductItem')
      },
      get title() {
        return $t('Dictionary.ProductItem')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Unit')
      },
      get title() {
        return $t('Dictionary.Unit')
      }
    },
    {
      name: 'stockItemName',
      is_visible: (item) => {
        return item.isRawMaterial
      },
      get short_title() {
        return $t('Dictionary.StockItem')
      },
      get title() {
        return $t('Dictionary.StockItem')
      }
    },
    {
      name: 'requestedQuantity',
      is_visible: (item) => {
        return item.isRawMaterial
      },
      type: 'quantity',
      get short_title() {
        return $t('Dictionary.Need')
      },
      get title() {
        return $t('Dictionary.Need')
      }
    },
    {
      name: 'requestedQuantity',
      is_visible: (item) => {
        return !item.isRawMaterial
      },
      type: 'quantity',
      get short_title() {
        return $t('Dictionary.Requested')
      },
      get title() {
        return $t('Dictionary.Requested')
      }
    },
    {
      name: 'onComingQuantity',
      is_visible: true,
      type: 'quantity',
      get short_title() {
        return $t('Dictionary.Oncoming')
      },
      get title() {
        return $t('Dictionary.Oncoming')
      }
    },

    {
      name: 'stockOnHandQuantity',
      is_visible: true,
      type: 'quantity',
      get short_title() {
        return $t('Dictionary.StockOnHand')
      },
      get title() {
        return $t('Dictionary.StockOnHand')
      }
    },
    {
      name: 'recommendedQuantity',
      is_visible: (item) => {
        return item.isRawMaterial
      },
      get short_title() {
        return $t('ProductionPlaning.recommendedQuantity')
      },
      get title() {
        return $t('ProductionPlaning.recommendedQuantity')
      }
    },
    {
      name: 'differenceQuantity',
      is_visible: (item) => {
        return !item.isRawMaterial
      },
      type: 'quantity',
      get short_title() {
        return $t('Dictionary.Difference')
      },
      get title() {
        return $t('Dictionary.Difference')
      }
    },

    {
      name: 'quantity',
      type: 'quantity',
      editable: true,
      validationRules: 'required|decimal:6|greater_than:0',
      is_visible: (item) => {
        return !item.isRawMaterial
      },
      get short_title() {
        return $t('Dictionary.Quantity')
      },
      get title() {
        return $t('Dictionary.Quantity')
      }
    },
    
    {
      name: 'quantity',
      type: 'portal',
      is_visible: (item) => {
        return item.isRawMaterial
      },
      get short_title() {
        return $t('Dictionary.Quantity')
      },
      get title() {
        return $t('Dictionary.Quantity')
      }
    },
    {
      name: 'requestType',
      type: 'portal',
      is_visible: (item) => {
        return item.isRawMaterial
      },
      get short_title() {
        return $t('OnHand.tableColumn_stockItemType')
      },
      get title() {
        return $t('OnHand.tableColumn_stockItemType')
      }
    },
    {
      name: 'baseUnitName',
      is_visible: (item) => {
        return !item.isRawMaterial
      },
      get short_title() {
        return $t('Dictionary.BaseUnit')
      },
      get title() {
        return $t('Dictionary.BaseUnit')
      }
    },
    {
      name: 'requestedStoreId',
      type: 'portal',
      is_visible: (item) => {
        return item.isRawMaterial
      },
      get short_title() {
        return $t('Dictionary.Store')
      },
      get title() {
        return $t('Dictionary.Store')
      }
    }
  ]
}
