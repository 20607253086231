<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('ProductionPlaning.removeHeaderTitle')",
  loading="deleteRawMaterialPlanningItem",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import moment from 'moment'

export default {

  name: 'ProductionHeaderRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'code',
        is_visible: true,
        short_title: this.$t('Dictionary.DocNo'),
        title: this.$t('Dictionary.DocNo')
      },
      {
        name: 'storeName',
        is_visible: true,
        short_title: this.$t('Dictionary.Store'),
        title: this.$t('Dictionary.Store')
      },
      {
        name: 'planningDate',
        is_visible: true,
        short_title: this.$t('Dictionary.Date'),
        title: this.$t('Dictionary.Date')
      }]
    }
  },

  async mounted () {
    const item = this.$route.params.item
    if (item) {
      this.info = {
        ...item,
        planningDate: moment(item.planningDate).format('YYYY-MM-DD')
      }
    } else {
      this.fetchRawMaterialPlanningHeader(this.$route.params.id)
        .then(res => {
          this.info = {
            ...res.data.rawMaterialHeader,
            planningDate: moment(res.data.rawMaterialHeader.planningDate).format('YYYY-MM-DD')
          }
        })
    }
  },
  methods: {
    ...mapActions('RawMaterialPlanning', [
      'deleteRawMaterialPlanning',
      'fetchRawMaterialPlanningHeader'
    ]),
    ...mapActions('Notify', ['notifyShow']),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteRawMaterialPlanning(this.info.id)
      if (res) {
        const message = this.$t('Global.successRemoveMessage', { field: this.$t('Dictionary.rawMaterialPlanningTitle') })
        this.notifyShow({ message })
        this.closePopup()
        this.$emit('getRawMaterialList')
      }
    }, 'deleteRawMaterialPlanningItem')
  }
}
</script>
