<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Production.removeDetailFormTitle')",
  loading="deleteProductionDetail",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RemoveProductionDetail',

  data () {
    return {
      fields: [{
        name: 'productionStockItemName',
        is_visible: true,
        short_title: this.$t('Dictionary.ProductItem'),
        title: this.$t('Dictionary.ProductItem')
      },
      {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Dictionary.Quantity'),
        title: this.$t('Dictionary.Quantity')
      },
      {
        name: 'unitName',
        is_visible: true,
        short_title: this.$t('Dictionary.Unit'),
        title: this.$t('Dictionary.Unit')
      }],
      item: {}
    }
  },

  async mounted () {
    const res = await this.fetchProductDetailInfo(this.$route.params.detailId)
    if (res) {
      this.item = {
        ...res.data.productionDetail,
        unitName: this.findUnit(res.data.productionDetail.unitId).name
      }
    }
  },

  computed: {
    ...mapGetters('Units', ['findUnit'])
  },
  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Production', [
      'deleteProductionDetail',
      'fetchProductDetailInfo',
      'fetchProduct'
    ]),

    ...mapMutations('Production', [
      'SHOW_DETAIL'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.deleteProductionDetail(this.$route.params.detailId)
      if (result) {
        const message = this.$t('Global.successDetailRemoveMessage', { field: this.$t('Dictionary.Production') })
        await this.fetchProduct(this.$route.params.id)
        this.notifyShow({ message })
        this.close()
      }
    }, 'itemRemove')
  }
}
</script>

<style lang="scss">
</style>
